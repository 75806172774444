import React, { Fragment, useState, useEffect } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Typography,
  Tabs,
  Tab,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

// import NotifyIcon from "../../assets/images/new-design/notify-icon.svg";
import DeleteConfirmModal from "../../components/common/Modal/deleteConfirmModal";

import PropTypes from "prop-types";
// import Avatar from '@material-ui/core/Avatar';
// import DoneAllIcon from "@material-ui/icons/DoneAll";
// import { numberFormatIntoShortForm } from "../common/Utils/utils";

import { useSelector, useDispatch } from "react-redux";
import {
  getNotifications_Call,
  markAllAsRead_Call,
} from "../../Redux/Reducers/Settings/userPreferenceSlice";
import CrossIcon from "../../assets/svgs/CrossIcon";

import NotificationsLoading from "../common/Loading/SekeletonLoading/NotificationsLoading";
import browserRoute from "../../constants/browserRoutes";
import { markAsViewed } from "../common/Utils/utils";
import {
  gettingInboxById,
  markAsReadNotification,
} from "../../Redux/Reducers/AlertCenter";
import { useNavigate } from "react-router-dom";
import Loading from "../common/ExpandableTable/Loading";
import { toast } from "react-toastify";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import { common } from "../../actions";
import DRCheckIconGreen from "../../assets/svgs/DRCheckIconGreen";
import DRCrossIcon from "../../assets/svgs/DRCrossIcon";
import DRNotifySUser from "../../assets/svgs/DRNotifySUser";
import DRNotifyMultiUser from "../../assets/svgs/DRNotifyMultiUser";
import ActionDropDown from "./ActionDropDown";
import DRNotifyFillter from "./DRNotifyFillter";
import { environment } from "../../constants/serverRoutes";

import { Button, Select } from "antd";
import DRReminderIcon from "../../assets/svgs/DRReminderIcon";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NotificationsDrawer = ({ notify, setNotify, newNotification }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const notificationsTypeOptions = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Alerts",
      label: "Alerts",
    },
    {
      value: "ClaimFollowUp",
      label: "Reminders",
    },
    {
      value: "ManualClaims",
      label: "ETL",
    },
  ];

  const ActionOptions = [
    {
      value: "Clear All",
      label: "Clear All",
      action: () => {
        setModalData({
          ...modalData,
          description: "Are you sure you want to delete all notifications?",
          type: deleteItemsEnum?.All,
        });
        setOpenDeleteModal(true);
      },
    },
    {
      value: "Show All Notifications",
      label: "Show All Notifications",
      action: () => {
        window.open(`${browserRoute?.ALL_NOTIFICATION}`);
      },
    },
  ];

  const {
    notificationArr: notifications,
    notificationTypeArr,
    notificationTypeArrLoader,
  } = useSelector((state) => state.npReducers.userPreference);
  const { loadingMore, pageSize } = useSelector(
    (state) => state.npReducers.filters.notificationsFilter
  );

  const { userId } = useSelector((state) => state.pReducers.user.loginUserData);
  const deleteItemsEnum = {
    All: "All",
    Individual: "Individual",
  };
  const [show, setShow] = React.useState(false);
  const [noticationType, setNotificationType] = useState({
    value: "All",
    label: "All",
  });

  const [showTableColumnFilter, setShowTableColumnFilter] = useState("");
  const [selectedAlert, setSelectedAlert] = useState({});
  const [deleteAlertLoading, setDeleteAlertLoading] = useState("");
  const [modalData, setModalData] = useState({
    title: "Confirm Delete!",
    description: "Are you sure you want to delete this alert?",
    type: deleteItemsEnum?.All,
  });
  const [value, setValue] = React.useState(0);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [details, setDetails] = useState({});
  const [selectedId, setSelectedId] = useState({});
  const [mainOutputList, setMainOutputlist] = useState(
    notifications?.length ? notifications : []
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAlertType, setSelectedAlertType] = useState("All");
  const handleShow = () => {
    setShow(!show);
  };
  // for tabs
  const handleChange = (event, newValue) => {
    // console.log("handleChange--->>", newValue);
    setNotificationType(newValue);
    if (newValue?.value === "All") {
      setMainOutputlist(notifications);
    } else {
      setMainOutputlist(
        notifications?.filter((item) => item?.type === newValue?.value)
      );
    }
    // setValue(newValue);
  };

  const InitialHandleChange = (newValue) => {
    // console.log("InitialHandleChange--->>", newValue);

    if (newValue && newValue.value) {
      // Check if newValue is valid
      if (newValue.value === "All") {
        setMainOutputlist(notifications);
      } else {
        setMainOutputlist(
          notifications?.filter((item) => item?.type === newValue.value)
        );
      }
    }
  };

  const markAllAsRead = () => {
    dispatch(markAllAsRead_Call());
  };
  const handleTableColumnOpen = () => {
    if (showTableColumnFilter === "open") {
      setShowTableColumnFilter("");
    } else {
      setShowTableColumnFilter("open");
    }
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleMarkAsReadSingle = async (id) => {
    let mainArr = [...mainOutputList];
    let newArr = [];

    mainArr?.map((item) => {
      if (item?.originId === id) {
        let obj = { ...item, isRead: true };

        newArr.push(obj);
      } else {
        newArr.push(item);
      }
    });

    setMainOutputlist(newArr);
  };
  const deleteIndividualAlert = async (id) => {
    setDeleteAlertLoading("loading");
    try {
      const res = await common?.deleteIndividualAlert({ userId, id });
      setDeleteAlertLoading("");
      setOpenDeleteModal(false);
      toast.success(`Notification cleared successfully!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(getNotifications_Call());
    } catch (error) {
      // toast.error(`Something went wrong!`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      setDeleteAlertLoading("");
    }
  };
  const deleteAllAlerts = async () => {
    setDeleteAlertLoading("loading");
    try {
      const res = await common?.deleteAllAlerts(userId);
      setDeleteAlertLoading("");
      setOpenDeleteModal(false);
      toast.success(`Notifications cleared successfully!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(getNotifications_Call());
    } catch (error) {
      // toast.error(`Something went wrong!`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      setDeleteAlertLoading("");
    }
  };
  const deleteAlert = () => {
    modalData?.type === deleteItemsEnum?.Individual
      ? deleteIndividualAlert(selectedAlert?.notificationId)
      : deleteAllAlerts();
  };
  const fetchDetails = async (id) => {
    if (id) {
      try {
        setLoadingDetails(true);
        const res = await gettingInboxById(id);

        if (res) {
          handleMarkAsReadSingle(id);
          setDetails(res);
          setLoadingDetails(false);
        } else {
          setDetails({});
          setLoadingDetails(false);
          // toast.error(`Something went wrong!`, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        }
      } catch (error) {
        setDetails({});
        setLoadingDetails(false);
      }
    }
  };
  const handleSelectKey = (row) => {
    if (showTableColumnFilter === "open") {
      setShowTableColumnFilter("");
    } else {
      setShowTableColumnFilter("open");
      fetchDetails(row?.originId);
      setSelectedId(row);
    }
  };
  const renderTableClaims = (item, index) => {
    const redirectToDetails = (val) => {
      window.open(`/ra-det/${val?.claimPaymentId}`);
    };
    const styles = {
      cursor: "pointer",
      color: "#0450a2",
    };
    if (index === 0) {
      return (
        <span
          onClick={() => {
            redirectToDetails(item);
          }}
          style={styles}
        >
          {item?.payerClaimControlNo}
        </span>
      );
    } else {
      return (
        <>
          ,{" "}
          <span
            onClick={() => {
              redirectToDetails(item);
            }}
            style={styles}
          >
            {item?.payerClaimControlNo}
          </span>
        </>
      );
    }
  };

  const renderQuery = (string) => {
    const obj = string ? JSON.parse(string)[0] : "";

    if (obj) {
      return <pre>{`${obj?.TableName} ${obj?.Operator} ${obj?.Value}`}</pre>;
    }
    return "";
  };

  const redirectManualClaims = (item, type) => {
    // console.log("redirectManualClaims--->>", item);
    if (type == "manualClaimRedirct") {
      if (item?.type == "ManualClaims") {
        // console.log("redirectManualClaims_1--->>", type, item);
        let sourceId = "01010101-0000-0000-0000-000000000000";
        window.open(
          `/${browserRoute?.LOGS_PAGE.split("/")[1]}/${sourceId}?&name=${
            item?.type
          }`
        );
      }
    } else if (type == "ReminderClaimRedirect") {
      // console.log("redirectManualClaims_2--->>", type, item);

      window.open(
        `${browserRoute.CLAIM_PAYMENT_835}?search=${item?.payerCNNumber}`
      );
    }
  };

  useEffect(() => {
    if (notify) {
      dispatch(getNotifications_Call());
    }
    if (notify && newNotification) {
      markAsViewed();
    }
  }, [notify, newNotification]);

  useEffect(() => {
    // noticationType
    noticationType && InitialHandleChange(noticationType);
    // setMainOutputlist(notifications);
  }, [notifications]);

  // useEffect(() => {
  //   console.log("=============--->>");
  //   console.log("notifications--->>", notifications);
  //   console.log("mainOutputList--->>", mainOutputList);
  // }, [notifications, mainOutputList]);

  // useEffect(() => {
  //   console.log("noticationType--->>", noticationType);
  // }, [noticationType]);

  return (
    <Drawer
      className="DR-Notification-side"
      anchor={"right"}
      open={notify}
      onClose={() => setNotify(false)}
      style={{ zIndex: "1600" }}
    >
      <div className="notification-sidebar center " role="presentation">
        <div
          className="side-bar-title flex items-center justify-between "
          style={{
            borderBottom: "1px solid rgb(239, 239, 239)",
            paddingBottom: "5px",
          }}
        >
          <Typography
            variant=""
            className="noticication-bar-title-1"
            style={{ fontWeight: 700 }}
          >
            Notifications
          </Typography>
          <div className="flex items-center">
            {mainOutputList?.length ? (
              <Typography
                variant=""
                className="noticication-bar-title-1"
                style={{
                  fontWeight: 400,
                  cursor: "pointer",
                  fontSize: "12px",
                  marginRight: "10px",
                  color: "#0450a2",
                }}
                onClick={() => {
                  setModalData({
                    ...modalData,
                    description:
                      "Are you sure you want to delete all notifications?",
                    type: deleteItemsEnum?.All,
                  });
                  setOpenDeleteModal(true);
                }}
              >
                Clear All
              </Typography>
            ) : null}

            {/* <CloseIcon className="close"  /> */}
            <div
              className="flex justify-center items-center cursor-pointer"
              onClick={() => setNotify(false)}
            >
              <CrossIcon />
            </div>
          </div>
        </div>

        <div
          className="side-bar-title flex items-center justify-between "
          style={{
            borderBottom: "1px solid rgb(239, 239, 239)",
            paddingBottom: "5px",
          }}
        >
          <div className="flex justify-between w-100 pt-2 ">
            <div>
              <Select
                value={noticationType?.value}
                className="DR-Notify-selct-drop"
                popupClassName="DR-Notify-selct-overly"
                dropdownStyle={{ width: "120px" }}
                disabled={loadingMore}
                style={{
                  width: "auto",
                }}
                onChange={handleChange}
                options={notificationsTypeOptions}
              />
              {/* <DRNotifyFillter /> */}

              {/* <DRNotifyFillter
                  disabled={notificationTypeArrLoader}
                  items={notificationTypeArr}
                  selectedType={selectedAlertType}
                  setSelectedAlertType={setSelectedAlertType}
                /> */}
            </div>

            <div>
              <Select
                className="DR-Notify-selct-drop"
                popupClassName="DR-Notify-selct-overly"
                dropdownStyle={{ width: "160px" }}
                style={{
                  width: "auto",
                }}
                value="Action"
                onChange={(e, newValue) => {
                  newValue?.action();
                }}
                options={ActionOptions}
              />
              {/* <ActionDropDown /> */}
            </div>
          </div>
        </div>

        <div style={{ maxHeight: "calc(100vh - 98px)", overflowY: "auto" }}>
          {loadingMore !== "" ? (
            <NotificationsLoading />
          ) : (
            <>
              <Fragment>
                <List style={{ paddingTop: "0px" }}>
                  {/* {environment == "dev" && (
                  <div className="DR-noti-del-btn-mian">
                    <ListItem
                      className="notication-bar-list-main"
                      style={{
                        borderBottom: "1px solid rgb(239, 239, 239)",
                      }}
                    >
                      <div className="d-flex w-100">
                        <Typography className=" w-100 mb-0">
                          <p className="noticication-bar-title">
                            <span className="Notify-type-icon">
                              <DRNotifyMultiUser />
                            </span>
                            <span className="DR-dot-unread DR-dot-new-un"></span>
                            <div>
                              <span className="noticication-bar-title-inner">
                                Please review your cliam follow-up. Prompt
                                action is requied to ensure timely processing.
                                Ensure all details are correct.
                              </span>
                              <div className="noticication-bar-title-inner flex">
                                <span className="noticication-bar-inner-bold">
                                  Payer CN:
                                </span>
                                <span className="noticication-bar-text-inner ml-2">
                                  ABC2232424424224
                                </span>
                              </div>
                              <div className="noticication-bar-title-inner flex">
                                <span className="noticication-bar-inner-bold">
                                  Follow-Up Date:
                                </span>
                                <span className="noticication-bar-text-inner ml-2">
                                  07-09-2024
                                </span>
                              </div>
                            </div>
                          </p>

                          <p
                            className="mb-0"
                            style={{
                              lineHeight: "14px",
                              marginTop: "5px",
                              marginLeft: "37px",
                            }}
                          >
                            <span className="noti-lable-remind">
                              Reminder: Claim Review
                            </span>
                          </p>
                        </Typography>
                      </div>
                    </ListItem>
                    <IconButton className="mark-comp DR-noti-del-btn">
                      <span className="DR-que-action">
                        <DeleteIcon />
                      </span>
                    </IconButton>
                  </div>
                )} */}
                  {mainOutputList?.length ? (
                    mainOutputList.map((item, i) => {
                      return (
                        <div className="DR-noti-del-btn-mian">
                          <ListItem
                            className="notication-bar-list-main"
                            style={{
                              borderBottom: "1px solid rgb(239, 239, 239)",
                              // backgroundColor: "#f3fff3",
                            }}
                            onClick={() => {
                              if (item?.originId && item?.notificationId) {
                                dispatch(
                                  markAsReadNotification([item?.notificationId])
                                );
                                // setNotify(false);
                                if (item?.type === "Alerts") {
                                  // window.open(
                                  //   `${browserRoute?.ALERT_CENTER_Details}/${item?.originId}`
                                  // );
                                  handleSelectKey(item);
                                }
                              }
                            }}
                            key={i}
                          >
                            <div className="d-flex w-100">
                              <Typography className=" w-100 mb-0">
                                <p className="noticication-bar-title">
                                  {/* Icon rendering based on notification type */}
                                  <span className="Notify-type-icon">
                                    {item?.type == "ManualClaims" && (
                                      <DRNotifySUser />
                                    )}
                                    {item?.type === "Alerts" && (
                                      <DRNotifyMultiUser />
                                    )}
                                    {item?.type == "ClaimFollowUp" && (
                                      <DRReminderIcon />
                                    )}
                                  </span>

                                  {/* Unread indicator */}
                                  {!item?.isViewed && (
                                    <span className="DR-dot-unread DR-dot-new-un"></span>
                                  )}

                                  {/* Notification message */}
                                  <span className="noticication-bar-title-inner">
                                    <span
                                      onClick={() =>
                                        redirectManualClaims(
                                          item,
                                          "manualClaimRedirct"
                                        )
                                      }
                                    >
                                      {item?.notification}{" "}
                                    </span>
                                    {/* {item?.notificationCount &&
                                      ` - ${item.notificationCount}`} */}
                                    <div
                                      className="noticication-bar-title-inner flex"
                                      style={{
                                        // paddingLeft: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <span className="noticication-bar-inner-bold">
                                        {item?.type == "ClaimFollowUp"
                                          ? "Follow up Date:"
                                          : item?.type == "Alerts"
                                          ? "Alert Date:"
                                          : item?.type == "ETL"
                                          ? "Date:"
                                          : item?.type == "ManualClaims"
                                          ? "Date:"
                                          : "Date:"}
                                      </span>
                                      <span className="noticication-bar-text-inner ml-2">
                                        {moment(item?.createdOn).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </span>
                                    </div>
                                  </span>
                                </p>

                                <p
                                  className="mb-0"
                                  style={{ lineHeight: "14px" }}
                                >
                                  {/* <div className="noticication-bar-title-inner flex">
                                    <span className="noticication-bar-inner-bold">
                                      Payer CN:
                                    </span>
                                    <span className="noticication-bar-text-inner ml-2">
                                      {item?.payerCNNumber
                                        ? item?.payerCNNumber
                                        : "-"}
                                    </span>
                                  </div> */}

                                  {/* <span className="noticication-bar-time-new ml-2 mr-2">
                                    -
                                  </span> */}
                                  <span className="noticication-bar-time-new ml-0">
                                    {item?.type == "ClaimFollowUp" && (
                                      <span
                                        onClick={() =>
                                          redirectManualClaims(
                                            item,
                                            "ReminderClaimRedirect"
                                          )
                                        }
                                      >
                                        <p
                                          className="mb-0"
                                          style={{
                                            lineHeight: "14px",
                                            marginTop: "5px",
                                            marginLeft: "37px",
                                          }}
                                        >
                                          <span className="noti-lable-remind">
                                            Reminder: Claim Review
                                          </span>
                                        </p>
                                      </span>
                                    )}
                                  </span>
                                </p>
                              </Typography>
                            </div>
                          </ListItem>
                          <IconButton
                            className="mark-comp DR-noti-del-btn"
                            // onClick={deleteComment}
                            onClick={() => {
                              setModalData({
                                ...modalData,
                                description:
                                  "Are you sure you want to delete this notification?",
                                type: deleteItemsEnum?.Individual,
                              });
                              setSelectedAlert(item);
                              setOpenDeleteModal(true);
                            }}
                          >
                            <span className="DR-que-action">
                              <DeleteIcon />
                            </span>
                          </IconButton>
                        </div>
                      );
                    })
                  ) : (
                    <ListItem className="notication-bar-list-main" button>
                      <ListItemText>
                        <Typography
                          className="noticication-bar-title"
                          style={{ fontWeight: 700 }}
                          variant="subtitle2"
                        >
                          No New Notification
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </Fragment>

              {/* end new notification */}

              {notifications?.length && notifications[0]?.totalCount > 10 ? (
                <Grid container>
                  <Grid
                    sm={12}
                    className="flex"
                    style={{
                      justifyContent: "center",
                      color: "#007a56",
                      textDecoration: "underline",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      onClick={() => {
                        window.open(`${browserRoute?.ALL_NOTIFICATION}`);
                      }}
                    >
                      Show all notifications
                    </span>
                  </Grid>
                </Grid>
              ) : null}
              {/* </TabPanel> */}
            </>
          )}
        </div>
      </div>

      {/* Details Alert */}
      <div
        className={
          "filter-sidebar dr-v2-side DR-Alet-msg " + showTableColumnFilter
        }
        // style={{ width: "550px" }}
      >
        <div>
          <div
            className="flex items-center justify-between py-2"
            style={{ borderBottom: "1px solid #efefef" }}
          >
            <h3 className="pl-3">Message</h3>
            <div className="flex items-center gap-1">
              <span
                className="pr-3 cursor-pointer flex items-center justify-center"
                onClick={handleTableColumnOpen}
              >
                <CrossIcon />
              </span>
            </div>
          </div>
          {loadingDetails ? (
            <div style={{ position: "relative" }}>
              <div className={"data-loading"}>
                <div className="cliam-ui-table-2">
                  <Loading compHeight="200" />
                </div>
              </div>
            </div>
          ) : (
            <div className="Alert-msg-main p-3">
              <h4
                //  className="Alert-msg-title"
                className="Alert-msg-text"
              >
                <strong>{details?.Name ? details?.Name : "-"}</strong>
              </h4>
              <p className="Alert-msg-text">
                {details?.Description ? details?.Description : ""}
              </p>
              <div className="mt-4 mb-4">
                <p className="Alert-msg-text mb-0">
                  <div>
                    <strong>Query: </strong>
                  </div>
                  <code>
                    <pre>
                      {renderQuery(details?.WhereJson)
                        ? renderQuery(details?.WhereJson)
                        : "-"}
                    </pre>
                  </code>
                </p>
              </div>
              <div className="mt-4 mb-4">
                <p className="Alert-msg-text mb-0">
                  <div>
                    <strong>Total Records: </strong>
                  </div>
                  <code>
                    <pre>
                      {details?.summaryDetail &&
                      details?.summaryDetail?.length > 0 &&
                      details?.summaryDetail[0]?.totalCount
                        ? details?.summaryDetail[0]?.totalCount
                        : "0"}
                      {details?.summaryDetail &&
                        details?.summaryDetail?.length > 0 &&
                        details?.summaryDetail[0]?.totalCount &&
                        details?.summaryDetail[0]?.totalCount > pageSize && (
                          <>
                            (Showing {pageSize} out of{" "}
                            {details?.summaryDetail[0]?.totalCount}){" "}
                          </>
                        )}
                    </pre>
                  </code>
                </p>
              </div>
              <div className="mt-4 mb-4">
                <p className="Alert-msg-text mb-0">
                  <div>
                    <strong>Claims:</strong>
                  </div>

                  {details?.summaryDetail && details?.summaryDetail?.length ? (
                    details?.summaryDetail?.map((item, index) => {
                      return <>{renderTableClaims(item, index)}</>;
                    })
                  ) : (
                    <span>No Record Found</span>
                  )}
                </p>
              </div>

              <p className="Alert-msg-text">
                <span
                  style={{
                    cursor: "pointer",
                    color: "#0450a2",
                  }}
                  onClick={() => {
                    window.open(
                      `${browserRoute?.ALERT_CENTER_Details}/${selectedId?.originId}?AlertName=${selectedId?.notification}`
                    );
                  }}
                >
                  See More
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      <DeleteConfirmModal
        modalData={modalData}
        handleConfirm={deleteAlert}
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        size="sm"
        action={true}
        loadingClass={deleteAlertLoading}
      />
    </Drawer>
  );
};

export default NotificationsDrawer;
