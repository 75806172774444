import React, { useState, useEffect, useRef } from "react";

import { TextField, Grid, IconButton } from "@mui/material";

import { Button, Popover } from "antd";
import { MentionsInput, Mention } from "react-mentions";

import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import CMSIcon from "../../assets/ZoeImages/cms.svg";
import FDAIcon from "../../assets/ZoeImages/fda.svg";
import PrivateIcon from "../../assets/ZoeImages/private.svg";

import BotIcon from "../../assets/ZoeImages/DRCopilot.svg";
import UserChatIcon from "../../assets/ZoeImages/Chatuser.svg";

import { Carousel } from "antd";
import DRNextArrow from "../../assets/svgs/DRNextArrow";
import DRPrevArrow from "../../assets/svgs/DRPrevArrow";

import withMainLayout from "../../HOC/withMainLayout";
import DRLogo from "../../assets/images/DRLogo.png";
import PolicyFile from "../../assets/ZoeImages/DRPrivacyPolicyCopilot.pdf";
import TermsFile from "../../assets/ZoeImages/DataRoversCopilotTermsandConditions.pdf";
import ChatbotLoading from "../../assets/images/ChatbotLoading.gif";
import CommentSendIcon from "../../assets/svgs/CommentSendIcon";
import PDFFileIcon from "../../assets/ZoeImages/pdf-file.svg";
import { environment, serverRoutes } from "../../constants/serverRoutes";
import genericAxiosCall, {
  checkTokenExpiration,
} from "../../AxiosConfig/genericAxiosCall";
import DetailContent from "../Claim835Detail/DetailContent";
import ChatIconBot from "./ChatIconBot";
import { formatDistanceToNow } from "date-fns";
import {
  helpReplaceWithIndex,
  ERROR_CHATBOT,
  removeDuplicatesFromArray,
  replaceDocAttached,
  replaceNonDocAttached,
  replaceWithCommas,
  replaceWithIndex,
  returnIndexDocAttached,
  returnIndexNonDocAttached,
  removeDuplicateFilePaths,
  removeTagsAttached,
  macAddressOptions,
  removePractitioner,
  toggleLoading,
  base64ToPdfAndDownload,
  base64ToPdfAndRedirect,
  removeTagsAttached2,
  enhanceUserMsg,
  encodeString,
  returnUserId,
  moduleRolesEnum,
  handle_Bot_Version_Response,
  enhanceBulletPoints,
  removeGarbageValues,
  getApplicationToken,
  makeBold,
  returnRawMsg,
  returnAnchorTag,
  neglectMessages,
  UnSuccessfullToast,
  ErrorToast,
  getUserId,
  getDomainId,
  payeeList,
  payeeListArr2,
  payeeListArrForPolicyCopilot,
  BCBS_AllowedStates,
  getListOfMessages,
} from "../../components/common/Utils/utils";
import CommentSendGreenZoe from "../../assets/svgs/CommentSendGreenZoe";
import DRCopyIcon from "../../assets/svgs/DRCopyIcon";
import TickIcon from "../../assets/svgs/TickIcon";
import MacDropDown from "./MacDropDown";
import BootstrapTooltip from "../../components/common/TooltipUI/customToolTip";
import { useSelector } from "react-redux";
import DRStopIcon from "../../assets/ZoeImages/DRStop";
import { useDispatch } from "react-redux";
import { setListingState } from "../../Redux/Reducers/filters/claimManagerFilters";
import defaultStyle from "./defaultStyle";
import Loading from "../../components/common/ExpandableTable/Loading";
import { DownloadIcon } from "../../assets/svgs/DownloadIcon";
import { common } from "../../actions";
import browserRoute from "../../constants/browserRoutes";
import DRPromptViewIcon from "../../assets/ZoeImages/DRPromptViewIcon";
import {
  changeMacAddress,
  copilotStates,
  generateGUID,
  getJurisdictionPreference,
  getMacAddressPreference,
  getPageNumber,
  POLICY_COPILOT_BOTNAME,
  PolicyCopilotSampleGroupQuestions,
  preserveChat,
  returnCotentMessage,
  sampleQuestionsPrompts,
  sampleQuestionsPromptsSuggested,
} from "../../components/common/Utils/copilot";
import DRLikeIcon from "../../assets/ZoeImages/DRLikeIcon";
import DRDisLikeIcon from "../../assets/ZoeImages/DRDisLikeIcon";
import MacDropDownZoe from "./MacDropDownZoe";
import { saveUserPreferences_Call2 } from "../../Redux/Reducers/Settings/userPreferenceSlice";
import Feedback from "./Feedback";
import DetailContentBot from "./DetailContentBot";
import CopilotNavDropDown from "./CopilotNavDropDown";
import DashboardTopNavBot from "../../components/DashboardTopNav/DashboardTopNavBot";
import {
  getCopilotHistoryOutputList,
  getCopilotHistoryOutputListTimestamp,
} from "../../Redux/Reducers/UploadPolicy/UploadPolicySlice";
import DRGroupQuestions from "../ChatBotResult/DRGroupQuestions";
import FeedbackPolicyCopilot from "./FeedbackPolicyCopilot";

const AskPolicyChat = () => {
  const sampleQuestions = [
    {
      image: CMSIcon,
      title: "CMS National",
      msg: `Inquire about policy coverage from CMS National for accurate guidance. `,
    },
    {
      image: PrivateIcon,
      title: "MAC",
      msg: `Seek advice from your Medicare Administrative Contractor for payment-related queries. `,
    },
    {
      image: PrivateIcon,
      title: "Private Payers",
      msg: `Get personalized coverage insights by consulting with private payers. `,
    },
    {
      image: FDAIcon,
      title: "FDA",
      msg: `Access procedural recommendations and regulatory insights from the FDA.`,
    },
  ];
  const botName = moduleRolesEnum?.Policy;
  const chatbotEnum = {
    bot: "bot",
    user: "user",
    policy: "policy",
    claim: "claim",
  };
  const data = [
    // {
    //   msg: "Welcome to the Datarovers Policy Copilot, How may I help you?",
    //   role: chatbotEnum?.bot,
    //   copy: false,
    //   feedback: false,
    // },
  ];

  const {
    userPreferences,
    denialCoordinatorsList,
    applicationToken,
    domainID,
    subscriptionID,
    loginUserData,
    modulePricing,
  } = useSelector((state) => state.pReducers.user);

  const { showTableColumnFilter, newChat } = useSelector(
    (state) => state.npReducers.filters.askZoeFilter
  );
  const { list: policyHashData } = useSelector(
    (state) => state.npReducers.copilot.policy
  );
  const { loadingMore } = useSelector(
    (state) => state.npReducers.filters.policyFilterMain
  );

  const textLimit = 1000;
  const [input, setInput] = useState("");
  const [inputTemp, setInputTemp] = useState("");
  const [intitalRender, setInitialRender] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const [mainOutput, setMainOutput] = useState([]);
  const [macAddress, setMacAddress] = useState(getMacAddressPreference());
  const [mainGuid, setMainGuid] = useState(
    localStorage.getItem("saved_Messages_Zoe_guid")
  );
  const [mainConversationTitle, setMainConversationTitle] = useState(
    localStorage?.getItem("saved_Messages_Zoe_Title")
  );
  const [initial, setInitial] = useState(false);
  const [jurisdictionOptions, setJurisdictionOptions] = useState(
    macAddress?.children
  );
  const [jurisdiction, setJurisdiction] = useState(getJurisdictionPreference());

  const onChangeSlide = (currentSlide) => {
    // console.log(currentSlide);
  };

  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [openCollaps, setOpenCollaps] = useState(true);
  const [referenceLoading, setReferenceLoading] = useState([]);
  const [isCopy, setIsCopy] = useState(false);
  const [copiedItem, setCopiedItem] = useState("");
  const [detailContent, setDetailContent] = useState("");
  const [openCollapsQuestion, setOpenCollapsQuestion] = useState(true);

  const moduleId = modulePricing?.[botName]?.ModuleId;

  const messagesDivRef = useRef(null);

  const handleWidthCollaps = () => {
    if (openCollaps === true) {
      setOpenCollaps(false);
    } else {
      setOpenCollaps(true);
    }
  };

  const scrollToBottom = () => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop =
        messagesDivRef.current.scrollHeight + 20;
    }
  };
  const handleLoading = (val, output) => {
    setLoading(val);
    if (val) {
      output.push({
        msg: "loading",
        role: "loading",
        citation: "",
      });
    } else {
      output.pop();
    }
    setMainOutput(output);
  };
  const handleChangeInput = (event) => {
    setInput(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.keyCode === 13) {
      setOpen(false);
    }
    if (
      event.keyCode === 13 &&
      !event.shiftKey &&
      input?.length <= textLimit &&
      !open
    ) {
      handleSubmit();
    }
  };
  const toggleStates = (key) => {
    if (key === "openCollaps") {
      setOpenCollaps(!openCollaps);
      setOpenCollapsQuestion(true);
    } else if (key === "openCollapsQuestion") {
      setOpenCollaps(true);
      setOpenCollapsQuestion(!openCollapsQuestion);
    }
  };
  const handleTableColumnOpen = () => {
    if (showTableColumnFilter === "open") {
      dispatch(
        setListingState({
          listing: "askZoeFilter",
          value: {
            showTableColumnFilter: "",
          },
        })
      );
    } else {
      dispatch(
        setListingState({
          listing: "askZoeFilter",
          value: {
            showTableColumnFilter: "open",
          },
        })
      );
    }
  };
  const handleSubmit = async (event) => {
    const inputTemp = input?.trim();
    if (inputTemp && inputTemp?.length <= textLimit) {
      dispatch(
        setListingState({
          listing: "askZoeFilter",
          value: {
            showTableColumnFilter: "",
          },
        })
      );
      setTimeout(scrollToBottom, 0);
      setOpen(false);

      let output = [...mainOutput];
      const selectedTagsTemp = [...selectedTags];
      const userMsgCheck = removePractitioner(
        // removeTagsAttached2(input, selectedTagsTemp)
        input
      );
      if (!userMsgCheck) {
        return;
      }
      setLoading(true);
      let userMsg = {
        msg: enhanceUserMsg(input, selectedTagsTemp),
        role: chatbotEnum?.user,
        citation: "",
        selectedTags: selectedTagsTemp,
        time: new Date().getTime(),
      };
      output.push(userMsg);
      setMainOutput(output);
      saveMessageToLocalHost(userMsg);
      setInput("");
      setInputTemp("");
      setSelectedTags([]);
      // fetchData(input, output, userMsg);
      const newMacAddress = macAddress;

      handleFetchClick(input, output, userMsg, newMacAddress, selectedTagsTemp);
    }
  };
  const showUrlTitle = (citation) => {
    if (
      citation?.title?.toLocaleLowerCase()?.includes(".html") &&
      citation?.url
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getConversationTitle = async (userMsg, answer) => {
    const data = {
      UserMsg: `Question:${userMsg}\nAnswer:${answer}`,
      chatSession: true,
      userId: getUserId(),
      moduleId: moduleId,
    };

    try {
      const res = await genericAxiosCall(
        serverRoutes?.GET_CHAT_TITLE,
        "post",
        data,
        ""
      );
      const title = res?.data?.data?.choices?.[0]?.message?.content;
      setMainConversationTitle(title);
      localStorage.setItem("saved_Messages_Zoe_Title", title);
      dispatch(getCopilotHistoryOutputListTimestamp());

      return title;

      // Return the title
    } catch (err) {
      console.log("hey khizer error===", err);
      return null;
    }
  };

  const generateHistoryMessage = async (userMsg, responseMsg, output) => {
    let title = mainConversationTitle;

    // If title is not already set, fetch it from getConversationTitle
    if (!title) {
      title = await getConversationTitle(userMsg, responseMsg); // Wait and capture the returned title
    }
    const body = {
      UserId: getUserId(),
      ConversationId: mainGuid,
      DomainId: getDomainId(),
      ConversationTitle: title,
      timestamp: new Date().getTime()?.toString(),
      RawMessages: JSON.stringify(output),

      ChatData: {
        Messages: getListOfMessages(output),
        // RawMessages: JSON.stringify(output),
        // timestamp: new Date().getTime(),
      },
    };
    genericAxiosCall(
      serverRoutes?.PRESERVE_POLICY_CHAT_HISTORY,
      "post",
      body,
      ""
    )
      .then((res) => {
        // console.log("hey khizer===", res);
      })
      .catch((err) => {});
  };
  const generateHistoryMessageFeedback = async (output) => {
    let title = mainConversationTitle;

    // If title is not already set, fetch it from getConversationTitle
    if (!title) {
      title = await getConversationTitle(userMsg, responseMsg); // Wait and capture the returned title
    }
    const body = {
      UserId: getUserId(),
      ConversationId: mainGuid,
      DomainId: getDomainId(),
      ConversationTitle: title,
      timestamp: new Date().getTime()?.toString(),
      RawMessages: JSON.stringify(output),

      ChatData: {
        Messages: getListOfMessages(output),
      },
    };
    genericAxiosCall(
      serverRoutes?.PRESERVE_POLICY_CHAT_HISTORY,
      "post",
      body,
      ""
    )
      .then((res) => {
        // console.log("hey khizer===", res);
      })
      .catch((err) => {});
  };
  const saveMessageToLocalHost = (obj) => {
    let savedMessages = localStorage.getItem("saved_Messages_Zoe");
    setTimeout(scrollToBottom, 0);
    if (savedMessages) {
      let arr = [];
      savedMessages = JSON.parse(savedMessages);

      arr.push(...savedMessages);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Zoe", JSON.stringify(arr));
    } else {
      let arr = [];
      //  savedMessages = JSON.parse(savedMessages);
      //  arr.push([...savedMessages]);
      // arr.push(tempMessage);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Zoe", JSON.stringify(arr));
    }
  };
  const loadSavedMessages = () => {
    const messages = JSON.parse(localStorage.getItem("saved_Messages_Zoe"));
    const guid = localStorage.getItem("saved_Messages_Zoe_guid");
    if (guid) {
      setMainGuid(guid);
    } else {
      const mainGuid = generateGUID();
      localStorage.setItem("saved_Messages_Zoe_guid", mainGuid);
      setMainGuid(guid);
      setInitial(true);
    }
    if (messages?.length) {
      messages.unshift(...data);
      setMainOutput(messages);
      setTimeout(scrollToBottom, 0);
    } else {
      setMainOutput(data);
    }
    setInitialRender(true);
  };

  const TimeAgo = ({ timestamp }) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    // const timeAgo = formatDistanceToNow(new Date(timestamp), {
    //   addSuffix: true,
    // });

    // if (timeAgo === "less than a minute ago") {
    //   return <span>just now</span>;
    // }

    // return <span>{timeAgo}</span>;
    if (timestamp) {
      const date = new Date(timestamp);

      const formattedDate = date.toLocaleString("en-US", options);

      return formattedDate;
    }

    return "";
  };
  const deleteSavedMessages = () => {
    localStorage.removeItem("saved_Messages_Zoe");
    localStorage.removeItem("saved_Messages_Zoe_guid");
    localStorage.removeItem("saved_Messages_Zoe_Title");
    setMainConversationTitle("");
    setMainOutput(data);
    dispatch(
      setListingState({
        listing: "askZoeFilter",
        value: {
          showTableColumnFilter: "",
        },
      })
    );
    nav(browserRoute?.ASK_ZOE);
  };
  const handleCopy = (msg, index) => {
    navigator.clipboard.writeText(msg);
    setCopiedItem(index?.toString());
    setIsCopy(true);
  };

  const DownloadButton = ({
    mainRow,
    citationString,
    citationIndex,
    referenceIndex,
  }) => {
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleClick = (item, citationString, title, indexMain) => {
      if (!downloadLoading) {
        setDownloadLoading(true);
        const option = indexMain;
        common
          .getPolicyDoc(
            title,
            item?.state,
            item?.index,
            item?.allMac,
            item?.stateMac
          )
          .then((res) => {
            if (res?.data?.content) {
              base64ToPdfAndDownload(
                res?.data?.content,
                res?.data?.contentType,
                title
              );
            } else {
              UnSuccessfullToast(res);
            }
          })
          .catch((err) => {
            ErrorToast(err);
          })
          .finally(() => {
            setDownloadLoading(false);
          });
      }
    };

    return (
      <>
        {downloadLoading ? (
          <span className="download-icon-bot">
            <Loading loaderWidth={20} loaderHeight={20} />
          </span>
        ) : (
          <span
            className="download-icon-bot"
            onClick={() => {
              handleClick(
                mainRow,
                citationString,
                citationIndex?.title,
                referenceIndex
              );
            }}
          >
            <DownloadIcon />
          </span>
        )}
      </>
    );
  };
  const handleSyntaxText_Policy = (item, indexMain) => {
    const msg = item?.msg;
    const citationString = item?.citation;
    const mainRow = item;
    const citationArr = removeDuplicatesFromArray(returnIndexDocAttached(msg));
    let citation = citationString ? JSON.parse(citationString) : "";

    if (citationArr?.length && citation && citation?.citations?.length) {
      citation = citation?.citations;
      let resMsg = replaceWithCommas(replaceDocAttached(msg));

      // const message = this.createChatBotMessage(<pre>{resMsg}</pre>);
      const newArr = [];
      citationArr?.sort((a, b) => a - b);

      citationArr?.map((item, index) => {
        const citationIndex = citation[Number(item) - 1];
        const splitContent = citationIndex?.content?.split("\n");
        if (splitContent && splitContent?.length) {
          newArr.push(citationIndex);
          resMsg = replaceWithIndex(resMsg, index + 1, item);
        }
      });

      const modifiedAndMergedArray = removeDuplicateFilePaths(newArr);

      resMsg = helpReplaceWithIndex(resMsg, modifiedAndMergedArray);
      let c = modifiedAndMergedArray?.map((item, index) => {
        let citationIndex = item;
        // const citationString = item?.content?.[`index${0}`];
        const contentMessage =
          item?.contentNew?.[Object?.keys(item?.contentNew)?.[0]];
        const citationString = returnCotentMessage(contentMessage);
        const referenceIndex = `${indexMain}-${citationIndex?.title}`;
        const splitContent = citationString?.split("\n");
        return (
          <>
            {splitContent && splitContent?.length && (
              <>
                <div className="DR-pdf-bot-file mb-1">
                  <div
                    // onClick={() => {
                    //   setDetailContent({
                    //     content: citationString,
                    //     mainRow: mainRow,
                    //     citationString: citationString,
                    //     title: citationIndex?.title,
                    //     referenceIndex: referenceIndex,
                    //   });
                    //   handleTableColumnOpen();
                    // }}
                    onClick={() => {
                      if (showUrlTitle(citationIndex)) {
                        window.open(citationIndex?.url);
                      } else {
                        setDetailContent({
                          referenceArr: modifiedAndMergedArray,
                          mainRow: mainRow,
                        });
                        handleTableColumnOpen();
                      }
                    }}
                    className="flex items-center"
                  >
                    <img src={PDFFileIcon} />
                    <span className="download-text-bot">
                      {`${Number(index + 1)}. ${
                        showUrlTitle(citationIndex)
                          ? citationIndex?.url
                          : citationIndex?.title
                      }`}
                    </span>
                  </div>

                  {/* {referenceLoading?.includes(referenceIndex) ? (
                    <span className="download-icon-bot">
                      <Loading loaderWidth={20} loaderHeight={20} />
                    </span>
                  ) : (
                    <span
                      className="download-icon-bot"
                      onClick={() => {
                        handleClick(
                          mainRow,
                          citationString,
                          citationIndex?.title,
                          referenceIndex
                        );
                      }}
                    >
                      <DownloadIcon />
                    </span>
                  )} */}
                  <DownloadButton
                    mainRow={mainRow}
                    citationString={citationString}
                    citationIndex={citationIndex}
                    referenceIndex={referenceIndex}
                  />
                </div>
              </>
            )}
          </>
        );
      });

      return (
        <pre style={{ whiteSpace: "pre-wrap" }}>
          <div dangerouslySetInnerHTML={{ __html: returnAnchorTag(resMsg) }} />
          <div className="mt-4">
            <div className="DR-chat-refrance">Reference:</div>
            <div key={indexMain}>{c}</div>
          </div>
        </pre>
      );
    } else {
      return (
        <pre
          dangerouslySetInnerHTML={{ __html: returnAnchorTag(msg) }}
          style={{ whiteSpace: "pre-wrap", fontSize: "13px" }}
        ></pre>
      );
    }
  };

  const getMessages = (newMsg, type) => {
    let newArr = [...mainOutput];
    let tempArr = [];
    newArr.shift();

    newArr?.map((item) => {
      const obj = {
        role: item?.role === chatbotEnum?.bot ? "assistant" : "user",
        content: item?.msg,
      };
      if (item?.citation) {
        const obj2 = {
          role: "tool",
          content: item?.citation,
        };
        tempArr.push(obj2);
      }
      tempArr.push(obj);
    });
    tempArr.push({
      role: "user",
      content: newMsg,
    });
    return tempArr;
  };

  const checkForAllowedUnit = (msg) => {
    const msgNew = msg?.toLocaleLowerCase();
    if (msgNew?.includes("unit") && msgNew?.includes("allow")) {
      return "units";
    } else if (
      msgNew?.includes("bundle") ||
      (msgNew?.includes("mutually") && msgNew?.includes("exclusive"))
    ) {
      return "bundles";
    } else {
      return payeeList?.cms?.value;
    }
  };
  const getBCBSIndextype = (msg) => {
    const currentState = macAddress?.value?.toLocaleLowerCase();
    const containsMac = BCBS_AllowedStates.find(
      (element) => currentState === element?.id
    );
    if (containsMac) {
      return containsMac?.value;
    } else {
      return "bcbs anthem";
    }
  };
  const getIndexType = (msg) => {
    if (msg) {
      let indexArr = [];
      msg = msg?.toLowerCase();
      const oragnizationLabel = {
        label: "custom",
        value: "custom",
      };
      const newPayeeList = {
        ...payeeList,
        [loginUserData?.OrganizationName?.replaceAll(
          " ",
          ""
        )?.toLocaleLowerCase()]: oragnizationLabel,
      };

      Object?.keys(newPayeeList)?.map((item) => {
        const itemLowerCase = item?.toLocaleLowerCase();

        if (msg?.includes(item?.toLocaleLowerCase())) {
          if (item === "bcbs") {
            indexArr.push(getBCBSIndextype(msg));
          } else {
            if (newPayeeList?.[item]?.variations) {
              const variations = newPayeeList?.[item]?.variations;
              const string = msg?.replaceAll(" ", "");
              const arr = [];

              Object?.keys(variations)?.map((variation) => {
                if (string?.includes(variation?.toLocaleLowerCase())) {
                  indexArr.push(variations?.[variation]?.value);
                  arr.push(variations?.[variation]?.value);
                }
              });
              if (!arr?.length) {
                indexArr.push(newPayeeList?.[item]?.value);
              }
            } else {
              indexArr.push(newPayeeList?.[item]?.value);
            }
          }
        }
      });

      if (indexArr?.length) {
        const indexArrNew = removeDuplicatesFromArray(indexArr);

        return indexArrNew?.toString();
      } else {
        return checkForAllowedUnit(msg);
      }
    }
  };
  const stopMessage = () => {
    let output = [...mainOutput];
    output.pop();
    setMainOutput(output);
    setLoading(false);
    cancelToken.cancel("Request canceled by the user");
    setCancelToken(axios.CancelToken.source());
  };

  const returnMacOrIndex = (indexType) => {
    if (indexType?.toLocaleLowerCase() === "cms") {
      return `${macAddress?.value?.toUpperCase()} MAC`;
    } else {
      return indexType?.toUpperCase();
    }
  };
  const enhanceResponse = (msg, indexType) => {
    const containsNeglectMessage = neglectMessages.some((neglectMsg) =>
      msg.includes(neglectMsg)
    );

    if (containsNeglectMessage) {
      msg = `The information you requested is not found in the ${returnMacOrIndex(
        indexType
      )} policy, please consider revising your query or selecting a different policy.`;
    }
    return msg;
  };
  const enhanceResponseAllMac = (msg, indexType) => {
    const containsNeglectMessage = neglectMessages.some((neglectMsg) =>
      msg.includes(neglectMsg)
    );
    if (containsNeglectMessage) {
      // msg = `The information you requested is not found in the ${returnMacOrIndex(
      //   indexType
      // )} policy, please consider revising your query or selecting a different policy.`;
      msg = `We couldn’t find the information in our knowledge base. Would you like extend your search to the internet?`;
    } else {
      msg =
        "We couldn’t find the information in your Default MAC policies, but we found a response from another MAC:\n\n" +
        msg;
    }
    return msg;
  };

  const hitExternalUrl = (msg) => {
    const containsNeglectMessage = neglectMessages?.some((neglectMsg) =>
      msg?.includes(neglectMsg)
    );

    if (containsNeglectMessage) {
      return true;
    } else {
      return false;
    }
  };

  const notFoundLink = (item) => {
    fetchDataWithSample2(item?.userMsg);
  };

  const fetchDataAttempt2 = async (msg, outputTemp, selectedTagsTemp) => {
    try {
      const index = getIndexType(msg)?.split(",")?.[0];
      const userMsg = removePractitioner(
        // removeTagsAttached2(msg, selectedTagsTemp)
        msg
      );

      const params = {
        userMsg: userMsg,
        chatSession: true,
        index: index,
        mac: jurisdiction?.mainValue,
        stateMac: true,
        userId: returnUserId(),
        moduleId: moduleId,
      };
      // handleLoading(true, outputTemp);

      await checkTokenExpiration();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getApplicationToken()}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const response = await axios.post(
        serverRoutes?.SEND_ASK_ROVERS3,
        params,
        {
          cancelToken: cancelToken.token,
          headers: headers,
        }
      );
      const checkMsg = response?.data?.data?.choices?.[0]?.message?.content;
      if (hitExternalUrl(checkMsg)) {
        fetchDataAllMac(msg, outputTemp, selectedTagsTemp);
      } else {
        if (handle_Bot_Version_Response(response)) {
          handleLoading(false, outputTemp);

          const {
            msg: responseMsg,
            citation,
            usage,
          } = handle_Bot_Version_Response(response);
          let output = [...outputTemp];
          const newMsg = enhanceBulletPoints(
            makeBold(enhanceResponse(removeGarbageValues(responseMsg), index))
          );

          const botMsg = {
            guid: generateGUID(),
            msg: newMsg,
            rawMsg: returnRawMsg(responseMsg),
            role: chatbotEnum?.bot,
            citation: citation,
            time: new Date().getTime(),
            copy: true,
            state: jurisdiction?.mainValue,
            index: index,
            showCopy: false,
            stateMac: true,
            userMsg: msg,
            usage: usage,
            // hitUrl: hitExternalUrl(responseMsg),
            feedback: true,
          };
          const preserveChatPayload = {
            ChatId: botMsg?.guid,
            UserId: returnUserId(),
            DomainId: domainID,
            CopilotType: "policy",
            Index: index,
            State: macAddress?.value,
            Jurisdiction: jurisdiction?.value,
            MAC: jurisdiction?.mainValue,
            DeviceType: window.navigator.userAgent,
            Message: {
              Question: msg,
              Answer: responseMsg,
            },
            Usage: usage,
          };
          preserveChat(preserveChatPayload);

          output.push(botMsg);
          generateHistoryMessage(userMsg, responseMsg, output);
          saveMessageToLocalHost(botMsg);
          setMainOutput(output);
        } else {
          const errorMsg = response?.data?.isCreditExpired
            ? response?.data?.message
            : ERROR_CHATBOT;

          handleLoading(false, outputTemp);
          let output = [...outputTemp];
          const botMsg = {
            msg: errorMsg,
            role: chatbotEnum?.bot,
            citation: "",
            time: new Date().getTime(),
          };
          // output.push(userMsg);
          output.push(botMsg);
          generateHistoryMessage(userMsg, errorMsg, output);

          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("khizer Request canceled:", error.message);
        // Handle canceled request if needed
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: ERROR_CHATBOT,
          // msg: error?.response?.data?.message
          //   ? error?.response?.data?.message
          //   : "Error occurred while fetching data.",
          role: chatbotEnum?.bot,
          citation: "",
          time: new Date().getTime(),
        };
        // output.push(userMsg);
        output.push(botMsg);
        generateHistoryMessage(msg, ERROR_CHATBOT, output);

        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      }
    }
  };
  const fetchData = async (
    msg,
    outputTemp,
    userMsg,
    newMacAddress,
    selectedTagsTemp
  ) => {
    try {
      const index = getIndexType(msg)?.split(",")?.[0];
      const userMsg = removePractitioner(
        // removeTagsAttached2(msg, selectedTagsTemp)
        msg
      );

      const params = {
        userMsg: userMsg,
        chatSession: true,
        index: index,
        mac: jurisdiction?.mainValue,
        userId: returnUserId(),
        moduleId: moduleId,
      };
      handleLoading(true, outputTemp);

      await checkTokenExpiration();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getApplicationToken()}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const response = await axios.post(
        serverRoutes?.SEND_ASK_ROVERS3,
        params,
        {
          cancelToken: cancelToken.token,
          headers: headers,
        }
      );
      const checkMsg = response?.data?.data?.choices?.[0]?.message?.content;
      if (hitExternalUrl(checkMsg)) {
        fetchDataAttempt2(msg, outputTemp, selectedTagsTemp);
      } else {
        if (handle_Bot_Version_Response(response)) {
          handleLoading(false, outputTemp);

          const {
            msg: responseMsg,
            citation,
            usage,
          } = handle_Bot_Version_Response(response);
          let output = [...outputTemp];
          const newMsg = enhanceBulletPoints(
            makeBold(enhanceResponse(removeGarbageValues(responseMsg), index))
          );

          const botMsg = {
            guid: generateGUID(),
            msg: newMsg,
            rawMsg: returnRawMsg(responseMsg),
            role: chatbotEnum?.bot,
            citation: citation,
            time: new Date().getTime(),
            copy: true,
            state: jurisdiction?.mainValue,
            index: index,
            showCopy: false,
            userMsg: msg,
            usage: usage,
            // hitUrl: hitExternalUrl(responseMsg),
            feedback: true,
          };
          const preserveChatPayload = {
            ChatId: botMsg?.guid,
            UserId: returnUserId(),
            DomainId: domainID,
            CopilotType: "policy",
            Index: index,
            State: macAddress?.value,
            Jurisdiction: jurisdiction?.value,
            MAC: jurisdiction?.mainValue,
            DeviceType: window.navigator.userAgent,
            Message: {
              Question: msg,
              Answer: responseMsg,
            },
            Usage: usage,
          };
          preserveChat(preserveChatPayload);

          output.push(botMsg);
          generateHistoryMessage(userMsg, responseMsg, output);
          saveMessageToLocalHost(botMsg);
          setMainOutput(output);
        } else {
          const errorMsg = response?.data?.isCreditExpired
            ? response?.data?.message
            : ERROR_CHATBOT;

          handleLoading(false, outputTemp);
          let output = [...outputTemp];
          const botMsg = {
            msg: errorMsg,
            role: chatbotEnum?.bot,
            citation: "",
            time: new Date().getTime(),
          };
          // output.push(userMsg);
          output.push(botMsg);
          generateHistoryMessage(userMsg, errorMsg, output);

          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("khizer Request canceled:", error.message);
        // Handle canceled request if needed
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: ERROR_CHATBOT,
          // msg: error?.response?.data?.message
          //   ? error?.response?.data?.message
          //   : "Error occurred while fetching data.",
          role: chatbotEnum?.bot,
          citation: "",
          time: new Date().getTime(),
        };
        // output.push(userMsg);
        output.push(botMsg);
        generateHistoryMessage(msg, ERROR_CHATBOT, output);

        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      }
    }
  };
  const fetchData2 = async (msg, outputTemp, userMsg, newMacAddress) => {
    try {
      // const params = {
      //   Messages: getMessages(msg),
      //   chatSession: true,
      // };
      const index = getIndexType(msg);
      const userMsg = removePractitioner(removeTagsAttached(msg));
      const params = {
        UserMsg: userMsg,
        chatSession: true,
        userId: returnUserId(),
        moduleId: moduleId,

        // index: index,
        // mac: macAddress,
      };
      handleLoading(true, outputTemp);
      // const response = await genericAxiosCall(
      //   serverRoutes?.SEND_ASK_ROVERS,
      //   "post",
      //   params,
      //   ""
      // );

      await checkTokenExpiration();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getApplicationToken()}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const response = await axios.post(
        serverRoutes?.SEND_ASK_COPILOT,
        params,
        {
          cancelToken: cancelToken.token,
          headers: headers,
        }
      );
      handleLoading(false, outputTemp);
      if (handle_Bot_Version_Response(response)) {
        const { msg: responseMsg, citation } =
          handle_Bot_Version_Response(response);
        let output = [...outputTemp];
        const newMsg = makeBold(
          enhanceResponse(removeGarbageValues(responseMsg), index)
        );
        const botMsg = {
          guid: generateGUID(),
          msg: newMsg,
          rawMsg: returnRawMsg(responseMsg),
          role: chatbotEnum?.bot,
          citation: citation,
          time: new Date().getTime(),
          copy: true,
          state: jurisdiction?.mainValue,
          index: index,
          showCopy: false,
          userMsg: userMsg,
          hitUrl: hitExternalUrl(responseMsg),
          feedback: true,
        };
        output.push(botMsg);
        generateHistoryMessage(userMsg, responseMsg, output);

        saveMessageToLocalHost(botMsg);
        setMainOutput(output);
      } else {
        const errorMsg = response?.data?.isCreditExpired
          ? response?.data?.message
          : ERROR_CHATBOT;
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: errorMsg,
          role: chatbotEnum?.bot,
          citation: "",
          time: new Date().getTime(),
        };
        // output.push(userMsg);
        output.push(botMsg);
        generateHistoryMessage(userMsg, errorMsg, output);
        saveMessageToLocalHost(botMsg);
        setMainOutput(output);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("khizer Request canceled:", error.message);
        // Handle canceled request if needed
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: ERROR_CHATBOT,
          // msg: error?.response?.data?.message
          //   ? error?.response?.data?.message
          //   : "Error occurred while fetching data.",
          role: chatbotEnum?.bot,
          citation: "",
          time: new Date().getTime(),
        };
        // output.push(userMsg);
        output.push(botMsg);
        generateHistoryMessage(msg, ERROR_CHATBOT, output);

        saveMessageToLocalHost(botMsg);
        setMainOutput(output);
      }
    }
  };
  const fetchDataAllMac = async (msg, outputTemp, selectedTagsTemp) => {
    try {
      // const params = {
      //   Messages: getMessages(msg),
      //   chatSession: true,
      // };
      const index = getIndexType(msg);
      const userMsg = removePractitioner(
        // removeTagsAttached2(msg, selectedTagsTemp)
        msg
      );
      const params = {
        userMsg: userMsg,
        chatSession: true,
        index: index,
        // mac: macAddress,
        allMac: true,
        mac: jurisdiction?.mainValue,
        userId: returnUserId(),
        moduleId: moduleId,
      };
      // handleLoading(true, outputTemp);

      await checkTokenExpiration();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getApplicationToken()}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const response = await axios.post(
        // serverRoutes?.SEND_ASK_ALL_MAC,
        serverRoutes?.SEND_ASK_ROVERS3,
        params,
        {
          cancelToken: cancelToken.token,
          headers: headers,
        }
      );
      handleLoading(false, outputTemp);

      if (handle_Bot_Version_Response(response)) {
        const {
          msg: responseMsg,
          citation,
          usage,
        } = handle_Bot_Version_Response(response);
        let output = [...outputTemp];
        const newMsg = enhanceBulletPoints(
          makeBold(
            enhanceResponseAllMac(removeGarbageValues(responseMsg), index)
          )
        );
        const botMsg = {
          guid: generateGUID(),
          msg: newMsg,
          rawMsg: returnRawMsg(responseMsg),
          role: chatbotEnum?.bot,
          citation: citation,
          time: new Date().getTime(),
          copy: true,
          state: jurisdiction?.mainValue,
          index: index,
          showCopy: false,
          userMsg: userMsg,
          allMac: true,
          hitUrl: hitExternalUrl(responseMsg),
          feedback: true,
          usage: usage,
        };
        const preserveChatPayload = {
          ChatId: botMsg?.guid,
          UserId: returnUserId(),
          DomainId: domainID,
          CopilotType: "policy",
          Index: index,
          State: macAddress?.value,
          Jurisdiction: jurisdiction?.value,
          MAC: jurisdiction?.mainValue,
          DeviceType: window.navigator.userAgent,
          Message: {
            Question: msg,
            Answer: responseMsg,
          },
          Usage: usage,
        };
        preserveChat(preserveChatPayload);
        output.push(botMsg);
        generateHistoryMessage(userMsg, responseMsg, output);

        saveMessageToLocalHost(botMsg);
        setMainOutput(output);
      } else {
        const errorMsg = response?.data?.isCreditExpired
          ? response?.data?.message
          : ERROR_CHATBOT;
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: errorMsg,
          role: chatbotEnum?.bot,
          citation: "",
          time: new Date().getTime(),
        };
        // output.push(userMsg);
        output.push(botMsg);
        generateHistoryMessage(userMsg, errorMsg, output);

        saveMessageToLocalHost(botMsg);
        setMainOutput(output);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("khizer Request canceled:", error.message);
        // Handle canceled request if needed
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: ERROR_CHATBOT,
          // msg: error?.response?.data?.message
          //   ? error?.response?.data?.message
          //   : "Error occurred while fetching data.",
          role: chatbotEnum?.bot,
          citation: "",
          time: new Date().getTime(),
        };
        // output.push(userMsg);
        output.push(botMsg);
        generateHistoryMessage(msg, ERROR_CHATBOT, output);
        saveMessageToLocalHost(botMsg);
        setMainOutput(output);
      }
    }
  };
  const handleFetchClick = (
    msg,
    outputTemp,
    userMsg,
    newMacAddress,
    selectedTagsTemp
  ) => {
    // setCancelToken(axios.CancelToken.source());
    fetchData(msg, outputTemp, userMsg, newMacAddress, selectedTagsTemp);
  };
  const drSampleQuestionClick = (item) => {
    if (!loading) {
      setInput(item);
      setInputTemp(item);
    }
  };
  const handlePromptView = async (item) => {
    item?.convertedIndexName
      ? setInputTemp(item?.convertedIndexName)
      : setInputTemp(item?.msg);
    item?.simpleIndex ? setInput(item?.simpleIndex) : setInput(item?.msg);
    item?.selectedTags && setSelectedTags(item?.selectedTags);
    setPromptOpen(false);
  };
  const removeHashes = (string) => {
    let remove = /#\[(.*?)\]/g;
    let result = string.replace(remove, "$1");
    return result;
  };

  const fetchDataWithSample2 = async (msg) => {
    setTimeout(scrollToBottom, 0);
    setOpen(false);
    let output = [...mainOutput];
    setLoading(true);
    let userMsg = {
      msg: msg,
      role: chatbotEnum?.user,
      citation: "",
      time: new Date().getTime(),
    };
    output.push(userMsg);
    setMainOutput(output);
    saveMessageToLocalHost(userMsg);
    setInput("");
    fetchData2(msg, output, userMsg);
  };
  const fetchDataWithAllMac = async (msg) => {
    setTimeout(scrollToBottom, 0);
    setOpen(false);
    let output = [...mainOutput];
    setLoading(true);
    let userMsg = {
      msg: msg,
      role: chatbotEnum?.user,
      citation: "",
      time: new Date().getTime(),
    };
    const newMacAddress = macAddress;
    output.push(userMsg);
    setMainOutput(output);
    saveMessageToLocalHost(userMsg);
    setInput("");
    fetchDataAllMac(msg, output, userMsg, newMacAddress);
  };
  const showQuestion = (item, mac) => {
    let show = true;
    if (item?.allow?.length) {
      if (!item?.allow?.includes(mac)) {
        show = false;
      }
    }

    return show;
  };
  const showQuestionSuggestion = (item, mac) => {
    let show = true;

    if (item?.allow?.length) {
      if (!item?.allow?.includes(mac)) {
        show = false;
      }
    }
    return show;
  };
  const renderPayerOptions = () => {
    return payeeListArrForPolicyCopilot;
  };
  const renderHashOptions = () => {
    if (policyHashData?.length) {
      let c = policyHashData?.map((item, index) => {
        return {
          id: index,
          display: item?.question,
        };
      });

      return c;
    } else {
      return [];
    }
  };

  // Chat History start
  const searchParams = new URLSearchParams(location.search);
  const routeId = searchParams.get("id");
  const nav = useNavigate();
  const mainGuidLocal = localStorage.getItem("saved_Messages_Zoe_guid");
  const [gettingHistoryLoading, setGettingHistoryLoading] = useState(false);
  const loadSavedMessagesHistory = () => {
    setGettingHistoryLoading(true);
    const params = {
      conversationId: routeId,
    };
    genericAxiosCall(serverRoutes?.GET_CHAT_DETAILS_BY_ID, "get", "", params)
      .then((res) => {
        const history = res?.data?.[0]?.rawMessages;
        const title = res?.data?.[0]?.conversationTitle;
        if (history) {
          localStorage.setItem("saved_Messages_Zoe", history);
          localStorage.setItem("saved_Messages_Zoe_guid", routeId);
          localStorage.setItem("saved_Messages_Zoe_Title", title);

          const messages = JSON.parse(history);
          const guid = routeId;
          setMainGuid(guid);
          setMainConversationTitle(title);
          if (messages?.length) {
            messages.unshift(...data);
            setMainOutput(messages);
            setTimeout(scrollToBottom, 0);
          } else {
            setMainOutput(data);
          }
        } else {
          UnSuccessfullToast("Unable to find the chat history");
          nav(browserRoute?.ASK_ZOE);
        }
      })
      .catch((err) => {
        ErrorToast(err);
        nav(browserRoute?.ASK_ZOE);
      })
      .finally(() => {
        setGettingHistoryLoading(false);
      });
    setInitialRender(true);
  };
  useEffect(() => {
    if (!routeId) {
      nav(browserRoute?.ASK_ZOE);
    }
    if (routeId === mainGuidLocal) {
      loadSavedMessages();
    } else {
      loadSavedMessagesHistory();
    }
    return () => {
      dispatch(
        setListingState({
          listing: "askZoeFilter",
          value: {
            showTableColumnFilter: "",
          },
        })
      );
      cancelToken.cancel("Component unmounted");
    };
  }, [routeId]);
  useEffect(() => {
    if (intitalRender) {
      deleteSavedMessages();
    }
  }, [newChat]);
  // Chat History end

  useEffect(() => {
    setTimeout(scrollToBottom, 0);
  }, [mainOutput]);

  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
        setCopiedItem("");
      }, 2000);
    }
  }, [isCopy]);

  return (
    <div>
      <DashboardTopNavBot />
      <div
        className="dashboard-main-tool-bar"
        style={{ marginTop: "40px" }}
      ></div>
      <div
        className="dashboard-main"
        style={{
          // backgroundColor: "#f2fbf9",
          backgroundColor: "#fff",
          height: "calc(100vh - 40px)",
          overflowY: "auto",
          paddingBottom: "150px",
        }}
        ref={messagesDivRef}
      >
        {loadingMore ? (
          <Loading />
        ) : (
          <div
            className="DR-Ava-main Copilot-main-new"
            style={{
              maxWidth:
                openCollapsQuestion && openCollaps === true
                  ? "100%"
                  : "calc(100% - 365px)",
            }}
          >
            <div className="DR-fix-wid-inner">
              <div
                className="DR-Custom-Chat-msg-main mt-4"
                style={{ paddingLeft: "20px" }}
              >
                <div className="DR-Ava-body-content-main">
                  <Grid container direction="row" alignItems="center">
                    {/* <Grid item sm={6}>
                    <h2 className="DR-Ava-body-content-title">DataRovers </h2>
                    <h2
                      className="DR-Ava-body-content-title-green"
                      style={{ color: "#07a784" }}
                    >
                      Policy Copilot
                    </h2>
                    <p>AI-Powered assistant for your Healthcare Claims</p>
                    <p>policy questions</p>
                  </Grid>
                  <Grid item sm={6}>
                    <img src={ZoeBotImage} className="DR-Ava-chat-image" />
                  </Grid> */}
                    {/* Mac Changes */}
                    <Grid item sm={12}>
                      <img
                        src={DRLogo}
                        style={{
                          width: "50px",
                          // margin: "0 auto",
                          display: "block",
                          marginLeft: "30px",
                        }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <p
                        style={{
                          fontSize: "30px",
                          fontWeight: "600",
                          marginTop: "20px",
                          paddingLeft: "30px",
                          // textAlign: "center",
                        }}
                      >
                        {POLICY_COPILOT_BOTNAME} is your AI-Powered
                      </p>
                      <p
                        style={{
                          fontSize: "30px",
                          fontWeight: "600",
                          marginTop: "7px",
                          color: "#a7a7a7",
                          paddingLeft: "30px",
                          // textAlign: "center",
                        }}
                      >
                        Assistant for payer policies
                      </p>

                      <div className="bot-qu-Slider DR-llm-new-slide mt-3">
                        <p
                          className="flex items-center"
                          style={{
                            color: "#a7a7a7",
                            fontSize: "14px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#a7a7a7"
                            width="20"
                            height="20"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                            ></path>
                          </svg>{" "}
                          Suggested
                        </p>
                        <Carousel
                          afterChange={onChangeSlide}
                          arrows={true}
                          nextArrow={
                            <span>
                              <DRNextArrow />
                            </span>
                          }
                          prevArrow={
                            <span>
                              <DRPrevArrow />
                            </span>
                          }
                          slidesToShow={3}
                          responsive={[
                            {
                              breakpoint: 1450,
                              settings: {
                                slidesToShow: 2,
                              },
                            },
                            {
                              breakpoint: 1200,
                              settings: {
                                slidesToShow: 1,
                              },
                            },
                          ]}
                        >
                          {sampleQuestionsPromptsSuggested?.map(
                            (item, index) => {
                              if (
                                showQuestionSuggestion(
                                  item,
                                  jurisdiction?.mainValue
                                )
                              ) {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      style={{ margin: "0 10px" }}
                                    >
                                      <button
                                        className="DR-Bot-choos-btn"
                                        onClick={() => {
                                          handlePromptView(item);
                                        }}
                                      >
                                        <div
                                          className="flex items-center"
                                          style={{ marginBottom: "5px" }}
                                        >
                                          <div>{item?.title}</div>
                                        </div>
                                        <p>{item?.titleMsg}</p>
                                      </button>
                                    </div>
                                  </>
                                );
                              }
                            }
                          )}
                        </Carousel>
                      </div>
                    </Grid>

                    <Grid item sm={12}>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          marginTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        {POLICY_COPILOT_BOTNAME} is powered by AI, so surprises
                        and mistakes are possible. Please share feedback so we
                        can improve!
                      </p>
                    </Grid>

                    {/* Mac Changes */}
                  </Grid>
                </div>

                {mainOutput?.map((item, index) => {
                  return item?.role === chatbotEnum?.bot ? (
                    <div
                      className="DR-Custom-Chat-msg-bot"
                      style={{
                        margin: "10px 10px 30px 10px",
                        paddingLeft: "30px",
                      }}
                      key={index}
                    >
                      <div
                        className="flex items-center"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginBottom: "5px",
                        }}
                      >
                        <img
                          style={{ width: "28px", marginRight: "5px" }}
                          src={BotIcon}
                        />
                        <span>{POLICY_COPILOT_BOTNAME}</span>
                      </div>

                      <div
                        className="DR-Custom-Chat-msg-bot-inner"
                        style={{
                          background: "none",
                          maxWidth: "95%",
                          padding: "0",
                          boxShadow: "none",
                        }}
                      >
                        {handleSyntaxText_Policy(item, index)}
                        <div
                          className="DR-like-box"
                          style={{ bottom: "-30px", left: "0", right: "auto" }}
                        >
                          {item?.copy && (
                            <button
                              disabled={index?.toString() === copiedItem}
                              style={{
                                background: "none",
                                boxShadow: "none",
                                // backgroundColor:
                                //   index?.toString() === copiedItem
                                //     ? "#f7f7f7"
                                //     : "#fff",
                                cursor:
                                  index?.toString() === copiedItem
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() => {
                                handleCopy(item?.rawMsg, index);
                              }}
                              className="Zoe-copy-btn"
                            >
                              {index?.toString() === copiedItem ? (
                                <span className="dr-tick">
                                  <TickIcon />
                                </span>
                              ) : (
                                <BootstrapTooltip title="Copy">
                                  <span>
                                    <DRCopyIcon />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </button>
                          )}
                          <FeedbackPolicyCopilot
                            item={item}
                            generateHistoryMessageFeedback={
                              generateHistoryMessageFeedback
                            }
                            index={index}
                            mainOutput={mainOutput}
                            setMainOutput={setMainOutput}
                          />
                        </div>

                        {item?.hitUrl && (
                          <div className="DR-chat-refrance mt-4">
                            <div>
                              Do you want to search this?{" "}
                              <button
                                className="DR-yes-no-btn"
                                disabled={loading}
                                onClick={() => {
                                  notFoundLink(item);
                                }}
                              >
                                Web
                              </button>
                              {/* <button
                                className="DR-yes-no-btn"
                                onClick={() => {
                                  fetchDataWithAllMac(item?.userMsg);
                                }}
                              >
                                All Mac
                              </button> */}
                            </div>
                          </div>
                        )}

                        {/* <div className="DR-chat-refrance">
                      <div className="DR-chat-refrance mt-4">
                        <div>
                          Do you want to search this on the web?{" "}
                          <button className="DR-yes-no-btn">No</button>
                          <button className="DR-yes-no-btn">Yes</button>
                        </div>
                      </div> */}
                      </div>
                      {/* <div className="DR-Custom-Chat-msg-user-inner-time">
                      {TimeAgo({ timestamp: item?.time })}
                    </div> */}
                    </div>
                  ) : item?.role === "loading" ? (
                    <div
                      className="DR-Custom-Chat-msg-bot"
                      style={{ margin: "10px 10px", paddingLeft: "30px" }}
                      key={index}
                    >
                      <div
                        className="flex items-center"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginBottom: "5px",
                        }}
                      >
                        <img
                          style={{ width: "28px", marginRight: "5px" }}
                          src={BotIcon}
                        />
                        <span>{POLICY_COPILOT_BOTNAME}</span>
                      </div>
                      <div
                        className="DR-Custom-Chat-msg-bot-inner flex items-center"
                        style={{ backgroundColor: "#fff", maxWidth: "95%" }}
                      >
                        <img
                          style={{ width: "50px", boxShadow: "none" }}
                          src={ChatbotLoading}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="DR-Custom-Chat-msg-user"
                      style={{ alignItems: "start", paddingLeft: "30px" }}
                      key={index}
                    >
                      <div
                        className="flex items-center"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginBottom: "5px",
                        }}
                      >
                        <img
                          style={{ width: "28px", marginRight: "5px" }}
                          src={UserChatIcon}
                        />
                        <span>You</span>
                      </div>
                      <div
                        className="DR-Custom-Chat-msg-user-inner"
                        style={{
                          background: "none",
                          maxWidth: "95%",
                          padding: "0",
                          boxShadow: "none",
                          color: "#131722",
                        }}
                      >
                        <pre
                          style={{
                            whiteSpace: "pre-wrap",
                            fontSize: "13px",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: item?.msg }}
                          />
                        </pre>
                      </div>
                      {/* <div className="DR-Custom-Chat-msg-user-inner-time">
                      {TimeAgo({ timestamp: item?.time })}
                    </div> */}
                    </div>
                  );
                })}

                {/* Khiar below commented div is for Loading */}
                {/* <div
                  className="DR-Custom-Chat-msg-bot"
                  style={{ margin: "10px 10px" }}
                >
                  <div
                    className="flex items-center"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    <img
                      style={{ width: "28px", marginRight: "5px" }}
                      src={BotIcon}
                    />
                    <span>Copilot</span>
                  </div>
                  <div
                    className="DR-Custom-genrating-Chat-msg-bot-inner flex items-center"
                    style={{ backgroundColor: "#fff", maxWidth: "95%" }}
                  >
                    Ok, I'll look tbd (dummy text)
                    <div className="moving-border-main">
                      <div
                        style={{ maxWidth: "70%" }}
                        className="moving-border"
                      ></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className={
                openCollapsQuestion && openCollaps === true
                  ? "DR-Custom-Chat-input-outer d-full"
                  : "DR-Custom-Chat-input-outer d-half"
              }
              // style={{
              //   zIndex: "5",
              //   backgroundColor: "#f2fbf9",
              //   maxWidth:
              //     openCollaps === true
              //       ? "calc(100% - 300px)"
              //       : "calc(100% - 660px)",
              // }}
            >
              <div
                style={{
                  position: "absolute",
                  // right: "135px",
                  right: "20px",

                  top: "0px",
                  fontSize: "12px",
                  color: "#a1a1a1",
                }}
              >
                {POLICY_COPILOT_BOTNAME} can make mistakes. Please double-check
                responses
              </div>
              {/* <div
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "-7px",
                  fontSize: "11px",
                }}
              >
                <Popover
                  open={promptOpen}
                  onOpenChange={(newOpen) => {
                    setPromptOpen(newOpen);
                  }}
                  content={
                    <div>
                      <Grid
                        container
                        direction="row"
                        alignItems="stretch"
                        spacing={3}
                      >
                        {sampleQuestionsPrompts?.map((item, index) => {
                          return (
                            <>
                              {showQuestion(item, jurisdiction?.mainValue) && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={4}
                                  key={index}
                                >
                                  <button
                                    className="DR-Bot-choos-btn"
                                    // style={{ backgroundColor: "#f7fbfe" }}
                                    onClick={() => {
                                      handlePromptView(item);
                                    }}
                                  >
                                    <div
                                      className="flex items-center"
                                      style={{ marginBottom: "5px" }}
                                    >
                                      <img
                                        src={item?.image}
                                        style={{
                                          width: "16px",
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                      <div>{item?.title}</div>
                                    </div>
                                    <p>{item?.msg}</p>
                                  </button>
                                </Grid>
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    </div>
                  }
                  title="Prompt Questions"
                  trigger="click"
                  placement="topRight"
                  overlayClassName="DR-view-promt-main"
                >
                  <Button className="DR-view-promt-btn">
                    <DRPromptViewIcon /> View Prompts
                  </Button>
                </Popover>
              </div> */}
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  bottom: "10px",
                  fontSize: "11px",
                  color: input?.length > textLimit ? "#d52816" : "#bcbcbc",
                }}
              >
                {`${input?.length}/${textLimit}`}
              </div>
              {/* <IconButton
                className="DR-chat-clear-icon"
                style={{ cursor: "pointer", backgroundColor: "#07a784" }}
                disabled={loading}
                onClick={deleteSavedMessages}
              >
                <ChatIconBot />{" "}
                <span className="DR-chat-clear-icon-text">New Topic</span>
              </IconButton> */}
              {/* {loading && (
              <IconButton
                className="DR-chat-clear-icon"
                style={{ cursor: "pointer", backgroundColor: "#07a784" }}
                onClick={stopMessage}
              >
                <span className="DR-chat-clear-icon-text">Stop</span>
              </IconButton>
                 )} */}
              <div
                className="DR-Custom-Chat-input"
                style={{
                  borderRadius: "30px",
                  marginLeft: "65px",
                }}
              >
                {/* <Mentions
                onSearch={(e) => {
                  setOpen(true);
                }}
                onSelect={(e) => {
                  setOpen(false);
                }}
                onBlur={(e) => {
                  setOpen(false);
                }}
                split=" "
                // disabled={loading}
                readOnly={loading}
                onKeyDown={handleKeyDown}
                onChange={(e, index) => {
                  setInput(e);
                }}
                value={input}
                rows={4}
                placeholder="Write your question here..."
                options={renderPayerOptions()}
                 /> */}
                <div className="single-line w-100">
                  <MentionsInput
                    // singleLine
                    className="DR-bot-input-main"
                    value={inputTemp}
                    onChange={(
                      event,
                      newValue,
                      newPlainTextValue,
                      mentions
                    ) => {
                      if (!loading) {
                        if (newValue?.includes("#[")) {
                          setInputTemp(removeHashes(newValue));
                          setInput(newPlainTextValue);
                        } else {
                          setInputTemp(newValue);
                          setInput(newPlainTextValue);
                          setSelectedTags(mentions);
                        }
                      }
                    }}
                    allowSuggestionsAboveCursor="true"
                    placeholder={
                      "Ask a policy question or use @ to select a Payer"
                    }
                    // a11ySuggestionsListLabel={"Suggested mentions"}

                    onKeyDown={handleKeyDown}
                    style={defaultStyle}
                    disabled={loading}
                  >
                    <Mention
                      data={renderPayerOptions()}
                      appendSpaceOnAdd
                      style={{
                        backgroundColor: "#cee4e5",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        borderRadius: "2px",
                      }}
                    />
                    <Mention
                      trigger="#"
                      data={renderHashOptions()}
                      allowSpaceInQuery
                      markup="#[__display__]"
                      // displayTransform={(id, display) => {
                      //   return display;
                      // }}
                      // renderSuggestion={(
                      //   entry,
                      //   search,
                      //   highlightedDisplay,
                      //   index,
                      //   focused
                      // ) => {

                      //   return <Loading />;
                      // }}
                    />
                  </MentionsInput>
                </div>

                {/* <TextField
                id="outlined-multiline-flexible"
                multiline
                minRows={2}
                maxRows={2}
                onChange={handleChangeInput}
                onKeyDown={handleKeyDown}
                placeholder="Write your question here..."
                inputMode="text"
                value={input}
                disabled={loading}
                style={{ width: "100%", height: "100%" }}
              /> */}
                {/* <BootstrapTooltip
                title={
                  <div className="DR-zoe-relase-notes">
                    <h4>Release Notes</h4>
                    <p>Payers Policies Count:</p>
                    <div>
                      <ol>
                        <li>CMS National = 327</li>
                        <li>CMS Local = 2016</li>
                        <ol>
                          <li>NGS = 126</li>
                          <li>CGS Administration= 395</li>
                          <li>Noridian = 480</li>
                          <li>Novitas Solutions = 90</li>
                          <li>Palmetto GBA = 297</li>
                        </ol>
                        <li>Aetna = 856</li>
                        <li>Humana = 397</li>
                        <li>Blue Cross Blue Shield</li>
                        <ol>
                          <li>BCBS California = 467</li>
                          <li>BCBS Illinois = 502</li>
                          <li>BCBS Anthem = 138</li>
                        </ol>
                        <li>NCCN Cancer Guidelines = 65 files</li>
                        <li>Oncology Drugs FDA Approvals = 62 files</li>
                        <li>Cigna = 1040</li>
                        <li>United Health Care = 2300</li>
                      </ol>
                    </div>
                  </div>
                }
              >
                <span className="flex DR-zoe-relase-icon">
                  <InfoIcon />
                </span>
              </BootstrapTooltip> */}
                {loading ? (
                  <button
                    className="pr-3"
                    onClick={stopMessage}
                    // style={{ right: "100px" }}
                  >
                    <BootstrapTooltip title="Stop">
                      <span className="DR-stop-icon">
                        <DRStopIcon />
                      </span>
                    </BootstrapTooltip>
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className={`${
                      input?.length && input?.length <= textLimit
                        ? "DR-input-icon-color-Zoe"
                        : ""
                    }`}
                    disabled={input?.length > textLimit}
                  >
                    <BootstrapTooltip title="Send Message">
                      <span>
                        {input?.length && input?.length <= textLimit ? (
                          <CommentSendGreenZoe />
                        ) : (
                          <CommentSendIcon />
                        )}
                      </span>
                    </BootstrapTooltip>
                  </button>
                )}
              </div>
            </div>
            {/* -------------------------------------------- */}
            {/* <div className="DR-Custom-Chat-input-outer">
            <div
              style={{
                position: "absolute",
                right: "20px",
                top: "-6px",
                fontSize: "11px",
                color: input?.length > textLimit ? "#d52816" : "#bcbcbc",
              }}
            >
              {`${input?.length}/${textLimit}`}
            </div>
            <IconButton
              className="DR-chat-clear-icon"
              style={{ cursor: "pointer", backgroundColor: "#07a784" }}
              disabled={loading}
              onClick={deleteSavedMessages}
            >
              <ChatIconBot />{" "}
              <span className="DR-chat-clear-icon-text">New Topic</span>
            </IconButton>
            <div
              className="DR-Custom-Chat-input"
              style={{
                borderRadius: "30px",
              }}
            >
              <TextField
                id="outlined-multiline-flexible"
                multiline
                minRows={2}
                maxRows={2}
                onChange={handleChangeInput}
                onKeyDown={handleKeyDown}
                placeholder="Write your question here..."
                inputMode="text"
                value={input}
                disabled={loading}
                style={{ width: "100%", height: "100%" }}
              />

              <button
                onClick={handleSubmit}
                className={`${
                  input?.length && input?.length <= textLimit
                    ? "DR-input-icon-color-Zoe"
                    : ""
                }`}
                disable={input?.length > textLimit}
              >
                {input?.length && input?.length <= textLimit ? (
                  <CommentSendGreenZoe />
                ) : (
                  <CommentSendIcon />
                )}
              </button>
            </div>
            </div> */}
          </div>
        )}

        {/* <DRCustomCopilotSide /> */}

        {/* <Button
          className="DR-side-btn-hover flex justify-center items-center"
          onClick={() => toggleStates("openCollaps")}
          style={{
            position: "fixed",
            top: "49px",
            right: openCollaps === true ? "40px" : "330px",
            border: "none",
            cursor: "pointer",
            backgroundColor: "#f3f3f3",
            borderRadius: "50%",
            minWidth: "35px",
            width: "35px",
            height: "35px",
            padding: "0",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#131722"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="24"
            height="24"
            stroke-width="1"
          >
            {" "}
            <path d="M4 16l6 -7l5 5l5 -6"></path>{" "}
            <path d="M15 14m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
            <path d="M10 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
            <path d="M4 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
            <path d="M20 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
          </svg>
        </Button> */}

        <button
          onClick={() => toggleStates("openCollaps")}
          style={{
            position: "fixed",
            top: "55px",
            // right: "40px",
            right: "85px",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          {openCollaps === true ? (
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-adjustments-horizontal"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M4 6l8 0" />
                <path d="M16 6l4 0" />
                <path d="M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M4 12l2 0" />
                <path d="M10 12l10 0" />
                <path d="M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M4 18l11 0" />
                <path d="M19 18l1 0" />
              </svg>
              {/* <span>Open</span> */}
            </div>
          ) : (
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
              {/* <span>Close</span> */}
            </div>
          )}
        </button>

        <div
          style={{
            position: "fixed",
            right: "20px",
            top: "70px",
            width: "285px",
            height: "100vh",
            paddingLeft: "15px",
            borderLeft: "1px solid #e9e9e9",
            display: openCollaps === true ? "none" : "block",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "15px",
                // textAlign: "center",
              }}
            >
              Choose your State
            </p>
            <div>
              <MacDropDownZoe
                options={copilotStates}
                value={macAddress}
                setValue={setMacAddress}
                mainMac={true}
                setJurisdictionOptions={setJurisdictionOptions}
                setJurisdiction={setJurisdiction}
                macAddress={macAddress}
              />
            </div>
          </div>
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "15px",
                // textAlign: "center",
              }}
            >
              Choose your Jurisdiction
            </p>
            <div>
              <MacDropDownZoe
                options={jurisdictionOptions}
                value={jurisdiction}
                setValue={setJurisdiction}
                macAddress={macAddress}
                jurisdiction={jurisdiction}
              />
            </div>
          </div>
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "15px",
                // textAlign: "center",
              }}
            >
              MAC
            </p>
            <div className="mac-drop-disable">
              <MacDropDownZoe
                options={jurisdictionOptions}
                value={jurisdiction}
                target="mainValue"
                disabled={true}
                setValue={setJurisdiction}
                macAddress={macAddress}
                jurisdiction={jurisdiction}
              />
            </div>
          </div>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              marginTop: "20px",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            <NavLink
              target="_blank"
              to={TermsFile}
              style={{ color: "#0450a2" }}
            >
              Terms
            </NavLink>{" "}
            |{" "}
            <NavLink
              target="_blank"
              to={PolicyFile}
              style={{ color: "#0450a2" }}
            >
              Privacy
            </NavLink>{" "}
            |{" "}
            <NavLink
              target="_blank"
              to="https://support.datarovers.ai/hc/en-us/articles/15333090262428-Payer-Policies"
              style={{ color: "#0450a2" }}
            >
              Policy Coverage
            </NavLink>{" "}
            {/* <BootstrapTooltip
              title={
                <div
                  className="DR-zoe-relase-notes"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <h4>Release Notes</h4>
                  <p>Payers Policies Count (12,321):</p>
                  <div>
                    <ol>
                      <li>CMS National = 327</li>
                      <li>CMS Local = 2330</li>
                      <ol>
                        <li>NGS = 187</li>
                        <li>CGS Administration= 573</li>
                        <li>Novitas Solutions = 260</li>
                        <li>Palmetto GBA = 297</li>
                        <li>Palmetto GBA = 297</li>
                        <li>Noridian = 623</li>
                        <li>First Coast Service Options, Inc = 137</li>
                        <li>WPS Insurance Corporation = 253</li>
                      </ol>
                      <li>Medicaid = 22</li>
                      <ol>
                        <li>Medicaid TX (Texas) = 22</li>
                      </ol>
                      <li>Aetna = 858</li>
                      <li>Humana = 1,534</li>
                      <li>Blue Cross Blue Shield</li>

                      <ol>
                        <li>BCBS California = 984</li>
                        <li>BCBS Illinois = 502</li>
                        <li>BCBS Anthem = 984</li>
                      </ol>
                      <li>NCCN Cancer Guidelines = 65 files</li>
                      <li>FDA Approvals = 93 files</li>
                      <li>Cigna = 1324</li>

                      <li>United Health Care = 3047</li>
                      <li>MCR MUE Practitioner Services Allowed Units</li>
                      <li>
                        MCR MUE Outpatient Hospital Services Allowed Units
                      </li>
                      <li>
                        NCCI PTP Edits for Bundling Practitioner/Outpatient
                      </li>
                      <li>Facility Fee Schedule </li>
                    </ol>
                  </div>
                </div>
              }
            >
              <span style={{ color: "#0450a2" }}>
                Release Notes
              </span>
            </BootstrapTooltip> */}
          </p>
        </div>
        <DRGroupQuestions
          title={POLICY_COPILOT_BOTNAME}
          sampleQuestion={PolicyCopilotSampleGroupQuestions}
          handleClick={drSampleQuestionClick}
          loading={loading}
          setOpenCollaps={setOpenCollapsQuestion}
          openCollaps={openCollapsQuestion}
          marginPadding={true}
          toggleFunction={true}
          toggleStates={toggleStates}
        />
      </div>
      <DetailContentBot
        showTableColumnFilter={showTableColumnFilter}
        handleTableColumnOpen={handleTableColumnOpen}
        detailContent={detailContent}
      />
    </div>
  );
};

export default withMainLayout(AskPolicyChat);
