import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  MenuItem,
  SwipeableDrawer,
  FormControlLabel,
  Switch,
  Grid,
} from "@mui/material";
// import { NavLink } from "react-router-dom";
import { Select } from "antd";

import CloseIcon from "@mui/icons-material/Close";
import {
  saveUserAdditionalDataIntoDB_Call,
  saveUserPreferences_Call,
} from "../../Redux/Reducers/Settings/userPreferenceSlice";
import {
  setAllFiltersNoOfRecordInTable,
  setPreferences,
  userData,
} from "../../Redux/Reducers/UserReducer";
import PrefrencesIcon from "../../assets/svgs/PrefrencesIcon";
import SingleTagsDropDown from "../common/filters/SingleTagsDropDown";
import { getAllDenialCoordinator } from "../../Redux/Reducers/user/userSlice";
import {
  macAddressOptions,
  setDefaultDateOnAllDashboards,
  timeZoneArray,
  getDefaultDateForDashboard2,
} from "../common/Utils/utils";
import { environment } from "../../constants/serverRoutes";
import {
  copilotStates,
  getJurisdictionPreference,
  getMacAddressPreference,
} from "../common/Utils/copilot";
import SingleTagsDropDownZoe from "../../pages/AskZoe/SingleTagsDropDownZoe";
import { setListingState } from "../../Redux/Reducers/filters/claimManagerFilters";
import { Iron } from "@mui/icons-material";

const { Option } = Select;

export default function NewPreference({ setOpen }) {
  const dispatch = useDispatch();

  const {
    userPreferences,
    denialCoordinatorsList,
    loginUserData,
    currentModule,
    userTimeZone,
  } = useSelector((state) => state.pReducers.user);

  const user2 = useSelector((state) => state.pReducers.user);

  // handle change filterPin
  const [filterPin, setFilterPin] = useState(
    userPreferences?.PinFilter ? userPreferences?.PinFilter : false
  );

  useEffect(() => {
    setFilterPin(
      userPreferences?.PinFilter ? userPreferences?.PinFilter : false
    );
  }, [userPreferences?.PinFilter]);

  const handleChangeFilterPin = (event) => {
    setFilterPin(event.target.checked);
  };

  const PeriodDashboardArr = [
    "Current Month",
    "Last Month",
    "Current Quarter",
    "Last Quarter",
    // "Last Session Date",
  ];

  if (user2?.AdditionalDetail) {
    PeriodDashboardArr.splice(
      PeriodDashboardArr.length,
      0,
      "Last Session Date"
    );
  }

  const defaultTableRow = [10, 15, 25, 30];
  const [selectedRow, setSelectedRow] = useState(
    userPreferences?.NoOfRecordInTable ? userPreferences?.NoOfRecordInTable : 10
  );
  const [selectedCoordinator, setSelectedCoordinator] = useState(
    userPreferences?.CoordinatorPreference
      ? userPreferences?.CoordinatorPreference
      : null
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    userTimeZone ? userTimeZone : null
  );
  const [selectedMacAddress, setSelectedMacAddress] = useState(
    getMacAddressPreference()
  );
  const [jurisdictionOption, setJurisdictionOption] = useState(
    selectedMacAddress?.children
  );
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(
    getJurisdictionPreference()
  );
  const defaultDateRange = ["1 Month", "2 Month", "3 Month"];

  const [timePeriod, seTimePeriod] = useState(
    userPreferences?.dashboardTimePeriod
      ? userPreferences?.dashboardTimePeriod
      : "None"
    // "None"
  );

  // Initial values
  const [initialTimePeriod] = useState(
    userPreferences?.dashboardTimePeriod || "None"
  );

  // filterPin
  const [initialFilterPin] = useState(userPreferences?.PinFilter || false);
  const [initialRow] = useState(userPreferences?.NoOfRecordInTable || "10");
  const [initialTimeZone] = useState(userTimeZone ? userTimeZone : null);

  const [loadingClass, setLoadingClass] = useState("");

  const [selectedDateRange, setSelectedDateRange] = useState(
    userPreferences?.DateRange ? userPreferences?.DateRange : "3 Month"
  );

  useEffect(() => {
    setSelectedTimeZone(userTimeZone);
  }, [userTimeZone]);

  // const classes = useStyles();
  const [state, setState] = useState({
    right: false,
  });

  // handle Change row
  function handleChangeRow(value) {
    setSelectedRow(value);
  }

  useEffect(() => {
    let row = localStorage.getItem("NoOfRecordInTable");
    // console.log(
    //   "userPreferences?.NoOfRecordInTable--->>",
    //   userPreferences?.NoOfRecordInTable,
    //   row
    // );
    setSelectedRow(
      userPreferences?.NoOfRecordInTable
        ? userPreferences?.NoOfRecordInTable
        : 10
    );
  }, [userPreferences?.NoOfRecordInTable]);

  // handle Change row

  // handle submit preferences data
  // const handleSubmit = async () => {
  //   try {
  //     let obj = {
  //       NoOfRecordInTable: `${selectedRow}`,
  //       Navigation: "left",
  //       Theme: "null",
  //       DateRange: `${selectedDateRange}`,
  //       PinFilter: filterPin,
  //       CoordinatorPreference:
  //         selectedCoordinator === "none" ? null : selectedCoordinator,
  //       // MacAddressPreference: selectedMacAddress,
  //       TimeZone: selectedTimeZone === "none" ? null : selectedTimeZone,
  //       MacAddressPreference: JSON.stringify({
  //         macAddress: selectedMacAddress,
  //         jurisdiction: selectedJurisdiction,
  //       }),
  //       dashboardTimePeriod: timePeriod,
  //     };
  //     // handleSavePreference(obj);
  //     setLoadingClass("loading");
  //     const res = await dispatch(saveUserPreferences_Call(obj));
  //     if (res?.success) {
  //       setState({ right: false });
  //       setOpen(false);
  //       const objClone = { ...obj }; // Create a shallow copy of the object
  //       delete objClone.TimeZone; // Remove the TimeZone key from the object

  //       dispatch(setPreferences(objClone)); // Dispatch the modified object

  //       dispatch(
  //         setAllFiltersNoOfRecordInTable({ noOfRecords: `${selectedRow}` })
  //       );

  //       if (timePeriod !== "None") {
  //         if (timePeriod !== "Last Session Date") {
  //           // ========== updating dashboard filters start ===========
  //           let result = getDefaultDateForDashboard2(timePeriod);
  //           // console.log("result--->>", result);
  //           //  add other dashboard filters as well
  //           const dashboardsFilterList = ["insightsSreensCommonFilter"];

  //           if (result) {
  //             // Get the URL parameters
  //             const urlParams = new URLSearchParams(window.location.search);

  //             // Update the URL based on the 'result' object
  //             urlParams.set("dateMain", result?.dateMainState || "Months");
  //             urlParams.set("month", result?.dateForMonth.value || "1");
  //             urlParams.set("year", result?.dateForYear.value || "2021");
  //             urlParams.set(
  //               "quarter",
  //               result?.dateForQuarter.value || "Q1-2025"
  //             );

  //             // You can now replace the current state in the browser's history with the new URL
  //             window.history.replaceState(
  //               {},
  //               document.title,
  //               `${window.location.pathname}?${urlParams.toString()}`
  //             );

  //             dashboardsFilterList.map((listing) =>
  //               dispatch(
  //                 setListingState({
  //                   listing,
  //                   value: {
  //                     dateForMonth: result?.dateForMonth,
  //                     dateForQuarter: result?.dateForQuarter,
  //                     dateForYear: result?.dateForYear,
  //                     dateMainState: result?.dateMainState,
  //                     from: result?.from,
  //                     to: result?.to,
  //                   },
  //                 })
  //               )
  //             );
  //             // ========== updating dashboard filters end ===========

  //             //  ===========   dispatch api for datePicker here  ===========
  //             var my_object = {
  //               date: {
  //                 dateForMonth: result?.dateForMonth,
  //                 dateForQuarter: result?.dateForQuarter,
  //                 dateForYear: result?.dateForYear,
  //                 dateMainState: result?.dateMainState,
  //                 from: result?.from,
  //                 to: result?.to,
  //               },
  //             };

  //             // console.log("my_object--->>", my_object);
  //             var object_as_string = JSON.stringify(my_object);

  //             dispatch(
  //               saveUserAdditionalDataIntoDB_Call({
  //                 AdditionalDetail: object_as_string,
  //                 UserId: user2?.loginUserData?.userId,
  //               })
  //             );

  //             // ======== call date dispatch here ========
  //           }
  //         } else {
  //           // last session date logic here

  //           if (user2?.AdditionalDetail) {
  //             const json = user2?.AdditionalDetail;
  //             const obj = JSON.parse(json);
  //             // console.log("obj--->>", obj);

  //             let {
  //               dateMainState,
  //               dateForMonth,
  //               dateForYear,
  //               dateForQuarter,
  //               from,
  //               to,
  //             } = obj?.date;

  //             dispatch(
  //               setListingState({
  //                 listing: "insightsSreensCommonFilter",
  //                 value: {
  //                   dateMainState: dateMainState,
  //                   dateForMonth: dateForMonth,
  //                   dateForYear: dateForYear,
  //                   dateForQuarter: dateForQuarter,
  //                   from: from,
  //                   to: to,
  //                 },
  //               })
  //             );
  //           } else {
  //             // first Time user click on last session date without last session
  //             // do nothing
  //           }
  //         }
  //       }

  //       //============= timeZone settings =============
  //       let finalUserModifiedObj = {};

  //       finalUserModifiedObj = {
  //         ...user2,
  //         userTimeZone: selectedTimeZone === "none" ? null : selectedTimeZone,
  //       };

  //       // console.log("finalUserModifiedObj--->>", finalUserModifiedObj);

  //       dispatch(userData(finalUserModifiedObj));

  //       //============= timeZone settings =============

  //       // await setDefaultDateOnAllDashboards(dispatch, obj?.dashboardTimePeriod);
  //       // window.location.reload();

  //       // Check for changes in selectedRow and selectedTimeZone
  //       if (
  //         selectedRow !== initialRow ||
  //         selectedTimeZone !== initialTimeZone
  //       ) {
  //         localStorage.setItem("NoOfRecordInTable", selectedRow);

  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 150);
  //       }
  //     }
  //   } catch (err) {
  //     console.log("err--->>", err);
  //   } finally {
  //     setLoadingClass("");
  //   }
  // };

  const handleSubmit = async () => {
    try {
      let obj = {
        NoOfRecordInTable: `${selectedRow}`,
        Navigation: "left",
        Theme: "null",
        DateRange: `${selectedDateRange}`,
        PinFilter: filterPin,
        CoordinatorPreference:
          selectedCoordinator === "none" ? null : selectedCoordinator,
        TimeZone: selectedTimeZone === "none" ? null : selectedTimeZone,
        MacAddressPreference: JSON.stringify({
          macAddress: selectedMacAddress,
          jurisdiction: selectedJurisdiction,
        }),
        dashboardTimePeriod: timePeriod,
      };

      setLoadingClass("loading");
      const res = await dispatch(saveUserPreferences_Call(obj));
      if (res?.success) {
        setState({ right: false });
        setOpen(false);

        const objClone = { ...obj }; // Shallow copy
        delete objClone.TimeZone;

        await dispatch(setPreferences(objClone));

        // Handle `timePeriod` changes
        if (timePeriod !== initialTimePeriod) {
          if (timePeriod !== "None") {
            if (timePeriod !== "Last Session Date") {
              let result = getDefaultDateForDashboard2(timePeriod);
              // console.log("handleSubmit_result--->>", result);
              const dashboardsFilterList = ["insightsSreensCommonFilter"];

              if (result) {
                const urlParams = new URLSearchParams(window.location.search);

                urlParams.set("dateMain", result?.dateMainState || "Months");
                urlParams.set("month", result?.dateForMonth.value || "1");
                urlParams.set("year", result?.dateForYear.value || "2021");
                urlParams.set(
                  "quarter",
                  result?.dateForQuarter.value || "Q1-2025"
                );

                window.history.replaceState(
                  {},
                  document.title,
                  `${window.location.pathname}?${urlParams.toString()}`
                );

                dashboardsFilterList.forEach((listing) =>
                  dispatch(
                    setListingState({
                      listing,
                      value: {
                        dateForMonth: result?.dateForMonth,
                        dateForQuarter: result?.dateForQuarter,
                        dateForYear: result?.dateForYear,
                        dateMainState: result?.dateMainState,
                        from: result?.from,
                        to: result?.to,
                      },
                    })
                  )
                );

                const myObject = {
                  date: {
                    dateForMonth: result?.dateForMonth,
                    dateForQuarter: result?.dateForQuarter,
                    dateForYear: result?.dateForYear,
                    dateMainState: result?.dateMainState,
                    from: result?.from,
                    to: result?.to,
                  },
                };

                await dispatch(
                  saveUserAdditionalDataIntoDB_Call({
                    AdditionalDetail: JSON.stringify(myObject),
                    UserId: user2?.loginUserData?.userId,
                  })
                );
              }
            } else {
              if (user2?.AdditionalDetail) {
                const obj = JSON.parse(user2?.AdditionalDetail);

                let {
                  dateMainState,
                  dateForMonth,
                  dateForYear,
                  dateForQuarter,
                  from,
                  to,
                } = obj?.date;

                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set("dateMain", dateMainState || "Months");
                urlParams.set("month", dateForMonth.value || "1");
                urlParams.set("year", dateForYear.value || "2021");
                urlParams.set("quarter", dateForQuarter.value || "Q1-2025");

                window.history.replaceState(
                  {},
                  document.title,
                  `${window.location.pathname}?${urlParams.toString()}`
                );

                await dispatch(
                  setListingState({
                    listing: "insightsSreensCommonFilter",
                    value: {
                      dateMainState,
                      dateForMonth,
                      dateForYear,
                      dateForQuarter,
                      from,
                      to,
                    },
                  })
                );
              }
            }
          }
        }

        // Handle `filtePin` changes
        if (filterPin !== initialFilterPin) {
          setTimeout(() => {
            window.location.reload();
          }, 150);
        }

        // Handle `selectedRow` changes
        if (selectedRow !== initialRow) {
          localStorage.setItem("NoOfRecordInTable", selectedRow);
          dispatch(
            setAllFiltersNoOfRecordInTable({ noOfRecords: `${selectedRow}` })
          );

          setTimeout(() => {
            window.location.reload();
          }, 150);
        }

        // Handle `selectedTimeZone` changes
        if (selectedTimeZone !== initialTimeZone) {
          let finalUserModifiedObj = {
            ...user2,
            userTimeZone: selectedTimeZone === "none" ? null : selectedTimeZone,
          };

          // console.log("finalUserModifiedObj--->>", finalUserModifiedObj);
          dispatch(userData(finalUserModifiedObj));

          setTimeout(() => {
            window.location.reload();
          }, 150);
        }

        // Handle timePeriod here
        if (timePeriod !== initialTimePeriod) {
          setTimeout(() => {
            window.location.reload();
          }, 150);
        }
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClass("");
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChangeDashboardTime = (e) => {
    seTimePeriod(e);
  };

  const list = (anchor) => (
    <div role="presentation">
      <div className="DR-pre-sidebar-title">
        <h3>Preferences </h3>
        <div className="actions">
          <CloseIcon onClick={toggleDrawer(anchor, false)} />
        </div>
      </div>
      <div
        className="DR-pre-sidebar"
        style={{ minHeight: "calc(100vh - 60px)", position: "relative" }}
      >
        <Grid container>
          {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" && (
              <Grid item sm={12}>
                <Box className=" mb-4">
                  <FormControlLabel
                    className="DR-Exp-swith DR-inner-smart ml-0"
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={filterPin}
                        onChange={handleChangeFilterPin}
                        name="filterPin"
                        color="primary"
                      />
                    }
                    label="Pinned Filter"
                  />
                </Box>
              </Grid>
            )}
          {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" && (
              <Grid item sm={12}>
                <label className="new-input-lable flex mb-1">
                  App Time Period
                </label>
                <Box className="input-new-design-icon mb-4">
                  <Select
                    // defaultValue="Current Month"
                    className="input-new-drop-st pref-drop-height"
                    style={{ width: "100%" }}
                    popupClassName="DR-pre-dro"
                    defaultOpen
                    value={timePeriod}
                    optionFilterProp="children"
                    onChange={handleChangeDashboardTime}
                  >
                    {PeriodDashboardArr.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Box>
              </Grid>
            )}

          <Grid item sm={12}>
            <label className="new-input-lable flex mb-1">
              No. of Rows (Table)
            </label>
            <Box className="input-new-design-icon mb-4">
              <Select
                defaultValue={selectedRow}
                className="input-new-drop-st pref-drop-height"
                style={{ width: "100%" }}
                popupClassName="DR-pre-dro"
                onChange={handleChangeRow}
                defaultOpen
              >
                {defaultTableRow.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Box>
          </Grid>
          {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" && (
              <Grid item sm={12}>
                <label className="new-input-lable flex mb-1">
                  Denials Coordinator
                </label>
                <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
                  <SingleTagsDropDown
                    data={denialCoordinatorsList}
                    defaultValue={userPreferences?.CoordinatorPreference}
                    selected={selectedCoordinator}
                    setSelected={setSelectedCoordinator}
                    labelTarget="coordinatorName"
                    valueTarget="coordinatorId"
                    none={true}
                  />
                </Box>
              </Grid>
            )}
          {currentModule.label == "Copilot" && (
            <Grid item sm={12}>
              <label className="new-input-lable flex mb-1">MAC</label>
              <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
                <SingleTagsDropDownZoe
                  data={copilotStates}
                  // defaultValue={getMacAddressPreference()}
                  selected={selectedMacAddress}
                  setSelected={setSelectedMacAddress}
                  labelTarget="label"
                  valueTarget="value"
                  none={false}
                  noneLabel={"Select your MAC"}
                  mainMac={true}
                  setJurisdictionOption={setJurisdictionOption}
                  setSelectedJurisdiction={setSelectedJurisdiction}
                />
              </Box>
              <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
                <SingleTagsDropDownZoe
                  data={jurisdictionOption}
                  // defaultValue={getMacAddressPreference()}
                  selected={selectedJurisdiction}
                  setSelected={setSelectedJurisdiction}
                  labelTarget="label"
                  valueTarget="value"
                  none={false}
                  noneLabel={"Select your Jurisdiction"}
                />
              </Box>
            </Grid>
          )}

          <Grid item sm={12}>
            <label className="new-input-lable flex mb-1">Time Zone</label>
            <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
              <SingleTagsDropDown
                data={timeZoneArray}
                defaultValue={selectedTimeZone}
                selected={selectedTimeZone}
                setSelected={setSelectedTimeZone}
                labelTarget="timeZoneTitleLabel"
                valueTarget="timeZoneTitleLabel"
                none={true}
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          className=""
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <Button
            style={{
              height: "44px",
            }}
            className={"new-coman-btn w-100 mt-2 " + loadingClass}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(getAllDenialCoordinator());
  }, []);

  useEffect(() => {
    console.log("timePeriod--->>", timePeriod);
  }, [timePeriod]);

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuItem onClick={toggleDrawer(anchor, true)}>
            <span className="link-inn-menu m-0 p-0 flex">
              <PrefrencesIcon color="#131722" />
              <span className="link-one-text pb-4 ml-3">Preferences</span>
            </span>
          </MenuItem>
          <SwipeableDrawer
            className="DR-pref-open"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            style={{ width: "400px" }}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
